import React, { useState, useEffect } from 'react'
import TextInput from '../../../components/Inputs/TextInput'
import SelectInput from '../../../components/Inputs/SelectInput'
import {
  validateString,
  smartValidateString,
} from '../../../validate/validators'
import { Country, ScreenProps } from '../../../types/common-types'
import styles from '../FiscalScreenOne.module.scss'
import { actions } from '../../../state-actions-reducers'
import { COUNTRY, INSTANCE } from '../../../server-parameters'
import { residenceCopyFromFactual, residenceCopyFromRegistered } from '../../../utils/helpers'

interface Props extends ScreenProps {
  addressCountries: Country[]
  regionCityRegistered: any
  regionCityFactual: any
  regionCityResidence: any
}

export default function AddressInputs({
  lang,
  state,
  dispatch,
  accountForm,
  addressCountries,
  invalidFields,
  regionCityRegistered,
  regionCityFactual,
  regionCityResidence,
}: Props) {
  const regionRegisteredListUnigue = [
    ...new Set(
      regionCityRegistered
        .map((item: any) => {
          return item.region
        })
        .sort((a: string, b: string) => {
          if (a.toLowerCase() < b.toLowerCase()) {
            return -1
          }
          if (a.toLowerCase() > b.toLowerCase()) {
            return 1
          }
          return 0
        })
        .sort((x: any, y: any) =>
          ['Алматинская область', 'Алматы облысы', 'Almaty'].includes(x)
            ? -1
            : 1
        ).sort((x: any, y: any) =>
        ['Санкт-Петербург и область', 'Санкт-Петербург және облыс', 'St. Petersburg and the region'].includes(x)
          ? -1
          : 1
        ).sort((x: any, y: any) =>
        ['Москва и Московская обл.', 'Мәскеу және Мәскеу облысы', 'Moscow and Moscow region'].includes(x)
          ? -1
          : 1
        )
    ),
  ]
  const cityRegisteredListForRegion = regionCityRegistered.filter(
    (item: any) => {
      if (item.region === state.addressRegisteredRegion) return item
    }
  )
  const regionRegisteredList = regionRegisteredListUnigue.map(item => {
    return {
      value: item,
      label: {
        rus: item,
        eng: item,
        kz: item,
      },
    }
  }).filter((e)=> (e.value !== 'Республика Крым' && INSTANCE === 'ala') || INSTANCE !== 'ala')
  const cityRegisteredList = cityRegisteredListForRegion
    ? cityRegisteredListForRegion
        .map((item: any) => {
          return {
            value: item.city,
            label: {
              rus: item.city,
              eng: item.city,
              kz: item.city,
            },
          }
        })
        .sort(
          (
            a: { value: string; label: string },
            b: { value: string; label: string }
          ) => {
            if (a.value.toLowerCase() < b.value.toLowerCase()) {
              return -1
            }
            if (a.value.toLowerCase() > b.value.toLowerCase()) {
              return 1
            }
            return 0
          }
        ).sort((x: any, y: any) =>
        ['Москва', 'Мәскеу', 'Moscow'].includes(x.value)
          ? -1
          : 1
        ).sort((x: any, y: any) =>
        ['Санкт-Петербург', 'Санкт-Петербург', 'Saint-Petersburg'].includes(x.value)
          ? -1
          : 1
        ).sort((x: any, y: any) =>
        ['Алматы', 'Алматы', 'Almaty'].includes(x.value)
          ? -1
          : 1
        )
    : []

  //#region Factual List

  const regionFactualListUnigue = [
    ...new Set(
      regionCityFactual
        .map((item: any) => {
          return item.region
        })
        .sort((a: string, b: string) => {
          if (a.toLowerCase() < b.toLowerCase()) {
            return -1
          }
          if (a.toLowerCase() > b.toLowerCase()) {
            return 1
          }
          return 0
        })
        .sort((x: any, y: any) =>
          ['Алматинская область', 'Алматы облысы', 'Almaty'].includes(x)
            ? -1
            : 1
        ).sort((x: any, y: any) =>
        ['Санкт-Петербург и область', 'Санкт-Петербург және облыс', 'St. Petersburg and the region'].includes(x)
          ? -1
          : 1
        ).sort((x: any, y: any) =>
        ['Москва и Московская обл.', 'Мәскеу және Мәскеу облысы', 'Moscow and Moscow region'].includes(x)
          ? -1
          : 1
        )
    ),
  ]
  const cityFactualListForRegion = regionCityFactual.filter((item: any) => {
    if (item.region === state.addressFactualRegion) return item
  })
  const regionFactualList = regionFactualListUnigue.map(item => {
    return {
      value: item,
      label: {
        rus: item,
        eng: item,
        kz: item,
      },
    }
  }).filter((e)=> (e.value !== 'Республика Крым' && INSTANCE === 'ala') || INSTANCE !== 'ala')
  const cityFactualList = cityFactualListForRegion
    ? cityFactualListForRegion
        .map((item: any) => {
          return {
            value: item.city,
            label: {
              rus: item.city,
              eng: item.city,
              kz: item.city,
            },
          }
        })
        .sort(
          (
            a: { value: string; label: string },
            b: { value: string; label: string }
          ) => {
            if (a.value.toLowerCase() < b.value.toLowerCase()) {
              return -1
            }
            if (a.value.toLowerCase() > b.value.toLowerCase()) {
              return 1
            }
            return 0
          }
        ).sort((x: any, y: any) =>
        ['Москва', 'Мәскеу', 'Moscow'].includes(x.value)
          ? -1
          : 1
        ).sort((x: any, y: any) =>
        ['Санкт-Петербург', 'Санкт-Петербург', 'Saint-Petersburg'].includes(x.value)
          ? -1
          : 1
        ).sort((x: any, y: any) =>
        ['Алматы', 'Алматы', 'Almaty'].includes(x.value)
          ? -1
          : 1
        )
    : []

  //#endregion
  //#region Residence List
  const regionResidenceListUnigue = [
    ...new Set(
      regionCityFactual
        .map((item: any) => {
          return item.region
        })
        .sort((a: string, b: string) => {
          if (a.toLowerCase() < b.toLowerCase()) {
            return -1
          }
          if (a.toLowerCase() > b.toLowerCase()) {
            return 1
          }
          return 0
        })
        .sort((x: any, y: any) =>
          ['Алматинская область', 'Алматы облысы', 'Almaty'].includes(x)
            ? -1
            : 1
        ).sort((x: any, y: any) =>
        ['Санкт-Петербург и область', 'Санкт-Петербург және облыс', 'St. Petersburg and the region'].includes(x)
          ? -1
          : 1
        ).sort((x: any, y: any) =>
        ['Москва и Московская обл.', 'Мәскеу және Мәскеу облысы', 'Moscow and Moscow region'].includes(x)
          ? -1
          : 1
        )
    ),
  ]
  const cityResidenceListForRegion = regionCityResidence?.filter((item: any) => {
    if (item.region === state.addressResidenceRegion) return item
  })
  const regionResidenceList = regionResidenceListUnigue.map(item => {
    return {
      value: item,
      label: {
        rus: item,
        eng: item,
        kz: item,
      },
    }
  })
  const cityResidenceList = cityResidenceListForRegion
    ? cityResidenceListForRegion
        .map((item: any) => {
          return {
            value: item.city,
            label: {
              rus: item.city,
              eng: item.city,
              kz: item.city,
            },
          }
        })
        .sort(
          (
            a: { value: string; label: string },
            b: { value: string; label: string }
          ) => {
            if (a.value.toLowerCase() < b.value.toLowerCase()) {
              return -1
            }
            if (a.value.toLowerCase() > b.value.toLowerCase()) {
              return 1
            }
            return 0
          }
        ).sort((x: any, y: any) =>
        ['Москва', 'Мәскеу', 'Moscow'].includes(x.value)
          ? -1
          : 1
        ).sort((x: any, y: any) =>
        ['Санкт-Петербург', 'Санкт-Петербург', 'Saint-Petersburg'].includes(x.value)
          ? -1
          : 1
        ).sort((x: any, y: any) =>
        ['Алматы', 'Алматы', 'Almaty'].includes(x.value)
          ? -1
          : 1
        )
    : []
  //#endregion

  const [disabledAddress, setDisabledAddress] = useState(false)
  const isResidenceAddressDisabled = state.addressResidenceCopyFromRegistered || state.addressResidenceCopyFromFactual

  useEffect(() => {
    if (state.addressFactualCopyFromRegistered) setDisabledAddress(true)
    if (
      state.addressRegisteredRegion.length > 0 &&
      !regionRegisteredList.find(item => {
        return item.value === state.addressRegisteredRegion
      })
    ) {
      dispatch({
        type: actions.ADDRESS_REGISTERED_REGION,
        data: '',
      })
      dispatch({
        type: actions.ADDRESS_REGISTERED_CITY,
        data: '',
      })
    }
    if (
      state.addressFactualRegion.length > 0 &&
      !regionFactualList.find(item => {
        return item.value === state.addressFactualRegion
      })
    ) {
      dispatch({
        type: actions.ADDRESS_FACTUAL_REGION,
        data: '',
      })
      dispatch({
        type: actions.ADDRESS_FACTUAL_CITY,
        data: '',
      })
    }
  }, [])
  return (
    <>
      {/* REGISTRATION ADDRESS */}
      <div className="form-group">
        <div className="row">
          <div className="col-12 col-xs-12">
            <h3>{accountForm.stepAddressRegisteredFiscal[lang]}</h3>
          </div>
          <div className="col-4 col-xs-12">
            {/* REGISTRATION ADDRESS COUNTRY */}
            <SelectInput
              testName={'addressRegisteredCountry'}
              testErrorName={'error-addressRegisteredCountry'}
              noOptionsMessage={accountForm.citizenshipInput.placeholder}
              lang={lang}
              disabled={!state.agreed}
              content={accountForm.addressCountry}
              options={addressCountries}
              onChange={id => {
                dispatch({
                  type: actions.ADDRESS_REGISTERED_COUNTRY,
                  data: id,
                })
                dispatch({
                  type: actions.ADDRESS_REGISTERED_REGION,
                  data: '',
                })
                dispatch({
                  type: actions.ADDRESS_REGISTERED_CITY,
                  data: '',
                })
                if (disabledAddress)
                  dispatch({
                    type: actions.ADDRESS_FACTUAL_COUNTRY,
                    data: id,
                  })
                if(state.addressResidenceCopyFromRegistered || (disabledAddress && state.addressResidenceCopyFromFactual))
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_COUNTRY,
                    data: id,
                  })
              }}
              valid={
                !invalidFields.includes('addressRegisteredCountry') &&
                validateString(state.addressRegisteredCountry, 300)
              }
              value={state.addressRegisteredCountry}
              prefix={
                state.iframeCountry === 'kz'
                ? 'urRegisteredAddressCountry'
                : 'urRegisteredAddressCountryRf'
              }
            />
          </div>
          <div className="col-4 col-xs-12">
            {/* REGISTRATION ADDRESS REGION */}
            {regionRegisteredList.length > 0 ? (
              <SelectInput
                testName={'addressRegisteredRegion'}
                testErrorName={'error-addressRegisteredRegion'}
                lang={lang}
                noOptionsMessage={accountForm.addressRegion.placeholder}
                content={accountForm.addressRegion}
                value={state.addressRegisteredRegion}
                options={regionRegisteredList}
                valid={
                  state.addressRegisteredRegion.length > 0
                    ? smartValidateString(state.addressRegisteredRegion) &&
                      !invalidFields.includes('addressRegisteredRegion')
                    : !invalidFields.includes('addressRegisteredRegion')
                }
                onChange={addressRegisteredRegion => {
                  dispatch({
                    type: actions.ADDRESS_REGISTERED_REGION,
                    data: addressRegisteredRegion,
                  })
                  dispatch({
                    type: actions.ADDRESS_REGISTERED_CITY,
                    data: '',
                  })
                  if (disabledAddress)
                    dispatch({
                      type: actions.ADDRESS_FACTUAL_REGION,
                      data: addressRegisteredRegion,
                    })
                  if(state.addressResidenceCopyFromRegistered || (disabledAddress && state.addressResidenceCopyFromFactual))
                    dispatch({
                      type: actions.ADDRESS_RESIDENCE_REGION,
                      data: addressRegisteredRegion,
                    })
                }}
                className={styles.input}
                prefix={
                  state.addressRegisteredCountry === '398'
                    ? 'urRegisteredAddressRegion' :
                  state.addressRegisteredCountry === '643'
                    ? 'urRegisteredAddressRegionRf'
                    : undefined
                }
              />
            ) : (
              <TextInput
                testName="addressRegisteredRegion"
                testErrorName="error-addressRegisteredRegion"
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.addressRegion}
                onChange={addressRegisteredRegion => {
                  dispatch({
                    type: actions.ADDRESS_REGISTERED_REGION,
                    data: addressRegisteredRegion,
                  })
                  if (disabledAddress)
                    dispatch({
                      type: actions.ADDRESS_FACTUAL_REGION,
                      data: addressRegisteredRegion,
                    })
                  if(state.addressResidenceCopyFromRegistered || (disabledAddress && state.addressResidenceCopyFromFactual))
                    dispatch({
                      type: actions.ADDRESS_RESIDENCE_REGION,
                      data: addressRegisteredRegion,
                    })
                }}
                valid={
                  state.addressRegisteredRegion.length > 0
                    ? smartValidateString(state.addressRegisteredRegion) &&
                      !invalidFields.includes('addressRegisteredRegion')
                    : !invalidFields.includes('addressRegisteredRegion')
                }
                value={state.addressRegisteredRegion}
              />
            )}
          </div>
          <div className="col-4 col-xs-12">
            {/* REGISTRATION ADDRESS CITY */}
            {regionRegisteredList.length > 0 ? (
              <SelectInput
                testName={'addressRegisteredCity'}
                testErrorName={'error-addressRegisteredCity'}
                disabled={!state.addressRegisteredRegion}
                lang={lang}
                noOptionsMessage={accountForm.addressCity.placeholder}
                content={accountForm.addressCity}
                value={state.addressRegisteredCity}
                optionValue={{
                  value: state.addressRegisteredCity,
                  label: state.addressRegisteredCity
                }}
                options={cityRegisteredList}
                valid={
                  state.addressRegisteredCity.length > 0
                    ? smartValidateString(state.addressRegisteredCity) &&
                      !invalidFields.includes('addressRegisteredCity')
                    : !invalidFields.includes('addressRegisteredCity')
                }
                onChange={addressRegisteredCity => {
                  dispatch({
                    type: actions.ADDRESS_REGISTERED_CITY,
                    data: addressRegisteredCity,
                  })
                  if (disabledAddress)
                    dispatch({
                      type: actions.ADDRESS_FACTUAL_CITY,
                      data: addressRegisteredCity,
                    })
                  if(state.addressResidenceCopyFromRegistered || (disabledAddress && state.addressResidenceCopyFromFactual))
                    dispatch({
                      type: actions.ADDRESS_RESIDENCE_CITY,
                      data: addressRegisteredCity,
                    })
                }}
                onCreateOption={
                  addressRegisteredCity => {
                    dispatch({
                      type: actions.ADDRESS_REGISTERED_CITY,
                      data: addressRegisteredCity,
                    })
                    if (disabledAddress)
                      dispatch({
                        type: actions.ADDRESS_FACTUAL_CITY,
                        data: addressRegisteredCity,
                      })
                  }
                }
                className={styles.input}
                prefix={
                  state.addressRegisteredCountry === '398' && ['Алматинская область', 'Алматы облысы', 'Almaty'].includes(state.addressRegisteredRegion)
                  ? 'urRegisteredAddressCity'
                  : state.addressRegisteredCountry === '643' && ['Москва и Московская обл.', 'Мәскеу және Мәскеу облысы', 'Moscow and Moscow region', 'Санкт-Петербург и область', 'Санкт-Петербург және облыс', 'St. Petersburg and the region'].includes(state.addressRegisteredRegion)
                  ? 'urRegisteredAddressCityRf'
                  : undefined
                }
                isSearchable={true}
                creatable={true}
              />
            ) : (
              <TextInput
                testName="addressRegisteredCity"
                testErrorName="error-addressRegisteredCity"
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.addressCity}
                onChange={addressRegisteredCity => {
                  dispatch({
                    type: actions.ADDRESS_REGISTERED_CITY,
                    data: addressRegisteredCity,
                  })
                  if (disabledAddress)
                    dispatch({
                      type: actions.ADDRESS_FACTUAL_CITY,
                      data: addressRegisteredCity,
                    })
                  if(state.addressResidenceCopyFromRegistered || (disabledAddress && state.addressResidenceCopyFromFactual))
                    dispatch({
                      type: actions.ADDRESS_RESIDENCE_CITY,
                      data: addressRegisteredCity,
                    })
                }}
                valid={
                  state.addressRegisteredCity.length > 0
                    ? smartValidateString(state.addressRegisteredCity) &&
                      !invalidFields.includes('addressRegisteredCity')
                    : smartValidateString(state.addressRegisteredCity)
                }
                value={state.addressRegisteredCity}
              />
            )}
          </div>
          <div className="col-4 col-xs-12">
            {/* REGISTRATION ADDRESS STREET */}
            <TextInput
              testName="addressRegisteredStreet"
              testErrorName="error-addressRegisteredStreet"
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.addressStreet}
              onChange={addressRegisteredStreet => {
                dispatch({
                  type: actions.ADDRESS_REGISTERED_STREET,
                  data: addressRegisteredStreet,
                })
                if (disabledAddress)
                  dispatch({
                    type: actions.ADDRESS_FACTUAL_STREET,
                    data: addressRegisteredStreet,
                  })
                if(state.addressResidenceCopyFromRegistered || (disabledAddress && state.addressResidenceCopyFromFactual))
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_STREET,
                    data: addressRegisteredStreet,
                  })
              }}
              valid={
                state.addressRegisteredStreet.length > 0
                  ? smartValidateString(state.addressRegisteredStreet) &&
                    !invalidFields.includes('addressRegisteredStreet')
                  : !invalidFields.includes('addressRegisteredStreet')
              }
              value={state.addressRegisteredStreet}
            />
          </div>
          <div className="col-1 col-xs-12">
            {/* REGISTRATION ADDRESS BUILDING */}
            <TextInput
              testName="addressRegisteredBuilding"
              testErrorName="error-addressRegisteredBuilding"
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.addressBuilding}
              onChange={addressRegisteredBuilding => {
                dispatch({
                  type: actions.ADDRESS_REGISTERED_BUILDING,
                  data: addressRegisteredBuilding,
                })
                if (disabledAddress)
                  dispatch({
                    type: actions.ADDRESS_FACTUAL_BUILDING,
                    data: addressRegisteredBuilding,
                  })
                if(state.addressResidenceCopyFromRegistered || (disabledAddress && state.addressResidenceCopyFromFactual))
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_BUILDING,
                    data: addressRegisteredBuilding,
                  })
              }}
              valid={
                validateString(state.addressRegisteredBuilding, 100) &&
                !invalidFields.includes('addressRegisteredBuilding')
              }
              value={state.addressRegisteredBuilding}
            />
          </div>
          <div className="col-1 col-xs-12">
            {/* REGISTRATION ADDRESS APARTMENTS */}
            <TextInput
              testName="addressRegisteredApartments"
              testErrorName="error-addressRegisteredApartments"
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.F_Office_Number}
              onChange={addressRegisteredApartments => {
                dispatch({
                  type: actions.ADDRESS_REGISTERED_APARTMENTS,
                  data: addressRegisteredApartments,
                })
                if (disabledAddress)
                  dispatch({
                    type: actions.ADDRESS_FACTUAL_APARTMENTS,
                    data: addressRegisteredApartments,
                  })
                if(state.addressResidenceCopyFromRegistered || (disabledAddress && state.addressResidenceCopyFromFactual))
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_APARTMENTS,
                    data: addressRegisteredApartments,
                  })
              }}
              valid={
                validateString(state.addressRegisteredApartments, 50) &&
                !invalidFields.includes('addressRegisteredApartments')
              }
              value={state.addressRegisteredApartments}
            />
          </div>
          <div className="col-2 col-xs-12">
            {/* REGISTERED ADDRESS APARTMENTS */}
            <TextInput
              testName="addressRegisteredPostCode"
              testErrorName="error-addressRegisteredPostCode"
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.F_Adress_Index}
              onChange={addressRegisteredPostCode => {
                dispatch({
                  type: actions.ADDRESS_REGISTERED_POSTCODE,
                  data: addressRegisteredPostCode.replace(/_/gim, ''),
                })
                if (disabledAddress)
                  dispatch({
                    type: actions.ADDRESS_FACTUAL_POSTCODE,
                    data: addressRegisteredPostCode.replace(/_/gim, ''),
                  })
                if(state.addressResidenceCopyFromRegistered || (disabledAddress && state.addressResidenceCopyFromFactual))
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_POSTCODE,
                    data: addressRegisteredPostCode.replace(/_/gim, ''),
                  })
              }}
              valid={
                validateString(state.addressRegisteredPostCode, 50) &&
                !invalidFields.includes('addressRegisteredPostCode')
              }
              mask={'*******'}
              required
              value={state.addressRegisteredPostCode}
            />
          </div>
          <div className="col-12 col-xs-12">
            {INSTANCE === 'ala' ?
              lang === 'eng' ? (
                <p className={styles.troubleMessage}>
                  If you have any difficulties completing the questionnaire,{' '}
                  <a href="tel:+77003571338">contact manager</a>
                </p>
              ) : lang === 'kz' ? (
                <p className={styles.troubleMessage}>
                  Сауалнаманы толтыруда қиындықтар болса,{' '}
                  <a href="tel:+77003571338">менеджермен хабарласыңыз</a>
                </p>
              ) : (
                <p className={styles.troubleMessage}>
                  Если у Вас возникли трудности при заполнении анкеты,{' '}
                  <a href="tel:+77003571338">свяжитесь с менеджером</a>
                </p>
              ) : INSTANCE === 'msk' ?
                lang === 'eng' ? (
                  <p className={styles.troubleMessage}>
                    If you have any difficulties completing the questionnaire,{' '}
                    <a href="tel:+74992888385">contact manager</a>
                  </p>
                ) : (
                  <p className={styles.troubleMessage}>
                    Если у Вас возникли трудности при заполнении анкеты,{' '}
                    <a href="tel:+74992888385">свяжитесь с менеджером</a>
                  </p>
                ) : lang === 'eng' ? (
                  <p className={styles.troubleMessage}>
                    If you have any difficulties completing the questionnaire,{' '}
                    <a href="tel:+79917792163">contact manager</a>
                  </p>
                ) : (
                  <p className={styles.troubleMessage}>
                    Если у Вас возникли трудности при заполнении анкеты,{' '}
                    <a href="tel:+79917792163">свяжитесь с менеджером</a>
                  </p>
                )
            }
          </div>
        </div>
      </div>
      {/* FACTUAL ADDRESS */}
      <div className="form-group">
        <div className="row">
          <div className="col-12 col-xs-12"></div>
          <div className="col-12 col-xs-12">
            <h3>{accountForm.stepAddressFactualFiscal[lang]}</h3>
          </div>
          <div className={styles.checkInputBox + ' ' + 'col-12 col-xs-12'}>
            <input
              data-test-id='addressFactualCopyFromRegistered'
              id="copyInput"
              name="copyInput"
              type="checkBox"
              onChange={e => {
                dispatch({
                  type: actions.ADDRESS_COPY_FROM_REGISTERED,
                  data: !state.addressFactualCopyFromRegistered,
                })
                setDisabledAddress(e.target.checked)
              }}
              checked={state.addressFactualCopyFromRegistered}
            />
            <label htmlFor="copyInput">
              {accountForm.fiscalAddressFactualCopyBtnDesc[lang]}
            </label>
          </div>
          <div className="col-4 col-xs-12">
            {/* FACTUAL ADDRESS COUNTRY */}
            <SelectInput
              testName={'addressFactualCountry'}
              testErrorName={'error-addressFactualCountry'}
              noOptionsMessage={accountForm.citizenshipInput.placeholder}
              lang={lang}
              disabled={!state.agreed || disabledAddress}
              content={accountForm.addressCountry}
              options={addressCountries}
              onChange={id => {
                dispatch({
                  type: actions.ADDRESS_FACTUAL_COUNTRY,
                  data: id,
                })
                dispatch({
                  type: actions.ADDRESS_FACTUAL_REGION,
                  data: '',
                })
                dispatch({
                  type: actions.ADDRESS_FACTUAL_CITY,
                  data: '',
                })
                if(state.addressResidenceCopyFromFactual)
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_COUNTRY,
                    data: id,
                  })
              }}
              valid={
                !invalidFields.includes('addressFactualCountry') &&
                validateString(state.addressFactualCountry, 3, false)
              }
              value={state.addressFactualCountry}
              prefix={
                state.iframeCountry === 'kz'
                ? 'urFactualAddressCountry'
                : 'urFactualAddressCountryRf'
              }
            />
          </div>
          <div className="col-4 col-xs-12">
            {/* FACTUAL ADDRESS REGION */}
            {regionFactualList.length > 0 ? (
              <SelectInput
                testName={'addressFactualRegion'}
                testErrorName={'error-addressFactualRegion'}
                disabled={disabledAddress}
                className={styles.input}
                lang={lang}
                noOptionsMessage={accountForm.addressRegion.placeholder}
                content={accountForm.addressRegion}
                value={state.addressFactualRegion}
                options={regionFactualList}
                valid={
                  state.addressFactualRegion.length > 0
                    ? smartValidateString(state.addressFactualRegion) &&
                      !invalidFields.includes('addressFactualRegion')
                    : !invalidFields.includes('addressFactualRegion')
                }
                onChange={addressFactualRegion => {
                  dispatch({
                    type: actions.ADDRESS_FACTUAL_REGION,
                    data: addressFactualRegion,
                  })
                  dispatch({
                    type: actions.ADDRESS_FACTUAL_CITY,
                    data: '',
                  })
                  if(state.addressResidenceCopyFromFactual)
                    dispatch({
                      type: actions.ADDRESS_RESIDENCE_REGION,
                      data: addressFactualRegion,
                    })
                }}
                prefix={
                  state.addressFactualCountry === '398'
                    ? 'urFactualAddressRegion' :
                    state.addressFactualCountry === '643'
                    ? 'urFactualAddressRegionRf'
                    : undefined
                }
              />
            ) : (
              <TextInput
                testName="addressFactualRegion"
                testErrorName="error-addressFactualRegion"
                disabled={!state.agreed || disabledAddress}
                lang={lang}
                content={accountForm.addressRegion}
                onChange={addressFactualRegion => {
                  dispatch({
                    type: actions.ADDRESS_FACTUAL_REGION,
                    data: addressFactualRegion,
                  })
                  if(state.addressResidenceCopyFromFactual)
                    dispatch({
                      type: actions.ADDRESS_RESIDENCE_REGION,
                      data: addressFactualRegion,
                    })
                }}
                valid={
                  state.addressFactualRegion.length > 0
                    ? smartValidateString(state.addressFactualRegion) &&
                      !invalidFields.includes('addressFactualRegion')
                    : !invalidFields.includes('addressFactualRegion')
                }
                value={state.addressFactualRegion}
              />
            )}
          </div>
          <div className="col-4 col-xs-12">
            {/* FACTUAL ADDRESS CITY */}
            {regionCityFactual ? (
              <SelectInput
                testName={'addressFactualCity'}
                testErrorName={'error-addressFactualCity'}
                className={styles.input}
                disabled={!state.addressFactualRegion || disabledAddress}
                lang={lang}
                noOptionsMessage={accountForm.addressCity.placeholder}
                content={accountForm.addressCity}
                value={state.addressFactualCity}
                optionValue={{
                  value: state.addressFactualCity,
                  label: state.addressFactualCity
                }}
                options={cityFactualList}
                valid={
                  state.addressFactualCity.length > 0
                    ? smartValidateString(state.addressFactualCity) &&
                      !invalidFields.includes('addressFactualCity')
                    : !invalidFields.includes('addressFactualCity')
                }
                onChange={addressFactualCity => {
                  dispatch({
                    type: actions.ADDRESS_FACTUAL_CITY,
                    data: addressFactualCity,
                  })
                  if(state.addressResidenceCopyFromFactual)
                    dispatch({
                      type: actions.ADDRESS_RESIDENCE_CITY,
                      data: addressFactualCity,
                    })
                }}
                onCreateOption={addressFactualCity => {
                  console.log('addressFactualCity', addressFactualCity)
                  dispatch({
                    type: actions.ADDRESS_FACTUAL_CITY,
                    data: addressFactualCity,
                  })
                }}
                prefix={
                  state.addressRegisteredCountry === '398' && ['Алматинская область', 'Алматы облысы', 'Almaty'].includes(state.addressFactualRegion)
                  ? 'urFactualAddressCity'
                  : state.addressFactualCountry === '643' && ['Москва и Московская обл.', 'Мәскеу және Мәскеу облысы', 'Moscow and Moscow region', 'Санкт-Петербург и область', 'Санкт-Петербург және облыс', 'St. Petersburg and the region'].includes(state.addressFactualRegion)
                  ? 'urFactualAddressCityRf'
                  : undefined
                }
                isSearchable={true}
                creatable={true}
              />
            ) : (
              <TextInput
                testName="addressFactualCity"
                testErrorName="error-addressFactualCity"
                disabled={disabledAddress}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.addressCity}
                onChange={addressFactualCity => {
                  dispatch({
                    type: actions.ADDRESS_FACTUAL_CITY,
                    data: addressFactualCity,
                  })
                  if(state.addressResidenceCopyFromFactual)
                    dispatch({
                      type: actions.ADDRESS_RESIDENCE_CITY,
                      data: addressFactualCity,
                    })
                }}
                valid={
                  validateString(state.addressFactualCity, 300) &&
                  !invalidFields.includes('addressFactualCity')
                }
                value={state.addressFactualCity}
                required
              />
            )}
          </div>
          <div className="col-4 col-xs-12">
            {/* FACTUAL ADDRESS STREET */}
            <TextInput
              testName="addressFactualStreet"
              testErrorName="error-addressFactualStreet"
              disabled={!state.agreed || disabledAddress}
              lang={lang}
              content={accountForm.addressStreet}
              onChange={addressFactualStreet => {
                dispatch({
                  type: actions.ADDRESS_FACTUAL_STREET,
                  data: addressFactualStreet,
                })
                if(state.addressResidenceCopyFromFactual)
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_STREET,
                    data: addressFactualStreet,
                  })
              }}
              valid={
                state.addressFactualStreet.length > 0
                  ? smartValidateString(state.addressFactualStreet) &&
                    !invalidFields.includes('addressFactualStreet')
                  : !invalidFields.includes('addressFactualStreet')
              }
              value={state.addressFactualStreet}
            />
          </div>
          <div className="col-1 col-xs-12">
            {/* FACTUAL ADDRESS BUILDING */}
            <TextInput
              testName="addressFactualBuilding"
              testErrorName="error-addressFactualBuilding"
              disabled={!state.agreed || disabledAddress}
              lang={lang}
              content={accountForm.addressBuilding}
              onChange={addressFactualBuilding => {
                dispatch({
                  type: actions.ADDRESS_FACTUAL_BUILDING,
                  data: addressFactualBuilding,
                })
                if(state.addressResidenceCopyFromFactual)
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_BUILDING,
                    data: addressFactualBuilding,
                  })
              }}
              valid={
                validateString(state.addressFactualBuilding, 300) &&
                !invalidFields.includes('addressFactualBuilding')
              }
              value={state.addressFactualBuilding}
              required
            />
          </div>
          <div className="col-1 col-xs-12">
            {/* FACTUAL ADDRESS APARTMENTS */}
            <TextInput
              testName="addressFactualApartments"
              testErrorName="error-addressFactualApartments"
              disabled={!state.agreed || disabledAddress}
              lang={lang}
              content={accountForm.F_Office_Number}
              onChange={addressFactualApartments => {
                dispatch({
                  type: actions.ADDRESS_FACTUAL_APARTMENTS,
                  data: addressFactualApartments,
                })
                if(state.addressResidenceCopyFromFactual)
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_APARTMENTS,
                    data: addressFactualApartments,
                  })
              }}
              valid={
                validateString(state.addressFactualApartments, 50) &&
                !invalidFields.includes('addressFactualApartments')
              }
              required
              value={state.addressFactualApartments}
            />
          </div>
          <div className="col-2 col-xs-12">
            {/* FACTUAL ADDRESS APARTMENTS */}
            <TextInput
              testName="addressFactualPostCode"
              testErrorName="error-addressFactualPostCode"
              disabled={!state.agreed || disabledAddress}
              lang={lang}
              content={accountForm.F_Adress_Index}
              onChange={addressFactualPostCode => {
                dispatch({
                  type: actions.ADDRESS_FACTUAL_POSTCODE,
                  data: addressFactualPostCode.replace(/_/gim, ''),
                })
                if(state.addressResidenceCopyFromFactual)
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_POSTCODE,
                    data: addressFactualPostCode,
                  })
              }}
              mask={'*******'}
              valid={
                validateString(state.addressFactualPostCode, 50) &&
                !invalidFields.includes('addressFactualPostCode')
              }
              required
              value={state.addressFactualPostCode}
            />
          </div>
        </div>
      </div>
      {/* RESIDENCE ADDRESS */}
      {state.F_FiscalType === '1' && COUNTRY === 'rf' ? 
      <div className="form-group">
        <div className="row">
          <div className="col-12 col-xs-12"></div>
          <div className="col-12 col-xs-12">
            <h3>{accountForm.stepAddressResidenceFiscal[lang]}</h3>
          </div>
          <div className={styles.checkInputBox + ' ' + 'col-12 col-xs-12'}>
            <input
              data-test-id='residenseAddressCopyFromFactual'
              id="copyInput_res_fact"
              name="copyInput"
              type="checkBox"
              onChange={e => {
                dispatch({
                  type: actions.RESIDENCE_ADDRESS_COPY_FROM_FACTUAL,
                  data: !state.addressResidenceCopyFromFactual,
                })
              }}
              checked={state.addressResidenceCopyFromFactual}
              disabled={state.addressResidenceCopyFromRegistered}
            />
            <label htmlFor="copyInput_res_fact">
              {accountForm.fiscalAddressFromFactualCopyBtnDesc[lang]}
            </label>
            <div style={{width: 16}}/>
            <input
              data-test-id='residenseAddressCopyFromRegistered'
              id="copyInput_res_reg"
              name="copyInput"
              type="checkBox"
              onChange={e => {
                dispatch({
                  type: actions.RESIDENCE_ADDRESS_COPY_FROM_REGISTERED,
                  data: !state.addressResidenceCopyFromRegistered,
                })
              }}
              checked={state.addressResidenceCopyFromRegistered}
              disabled={state.addressResidenceCopyFromFactual}
            />
            <label htmlFor="copyInput_res_reg">
              {accountForm.fiscalAddressFactualCopyBtnDesc[lang]}
            </label>
          </div>
          <div className="col-4 col-xs-12">
            {/* RESIDENCE ADDRESS COUNTRY */}
            <SelectInput
              testName={'addressResidenceCountry'}
              testErrorName={'error-addressResidenceCountry'}
              noOptionsMessage={accountForm.citizenshipInput.placeholder}
              lang={lang}
              disabled={!state.agreed || isResidenceAddressDisabled}
              content={accountForm.addressCountry}
              options={addressCountries}
              onChange={id => {
                dispatch({
                  type: actions.ADDRESS_RESIDENCE_COUNTRY,
                  data: id,
                })
                dispatch({
                  type: actions.ADDRESS_RESIDENCE_REGION,
                  data: '',
                })
                dispatch({
                  type: actions.ADDRESS_RESIDENCE_CITY,
                  data: '',
                })
              }}
              valid={
                !invalidFields.includes('addressResidenceCountry') &&
                validateString(state.addressResidenceCountry, 3, false)
              }
              value={state.addressResidenceCountry}
              prefix={
                state.iframeCountry === 'kz'
                ? 'urResidenceAddressCountry'
                : 'urResidenceAddressCountryRf'
              }
            />
          </div>
          <div className="col-4 col-xs-12">
            {/* RESIDENCE ADDRESS REGION */}
            {regionResidenceList.length > 0 ? (
              <SelectInput
                testName={'addressResidenceRegion'}
                testErrorName={'error-addressResidenceRegion'}
                disabled={isResidenceAddressDisabled}
                className={styles.input}
                lang={lang}
                noOptionsMessage={accountForm.addressRegion.placeholder}
                content={accountForm.addressRegion}
                value={state.addressResidenceRegion}
                options={regionResidenceList}
                valid={
                  state.addressResidenceRegion.length > 0
                    ? smartValidateString(state.addressResidenceRegion) &&
                      !invalidFields.includes('addressResidenceRegion')
                    : !invalidFields.includes('addressResidenceRegion')
                }
                onChange={addressResidenceRegion => {
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_REGION,
                    data: addressResidenceRegion,
                  })
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_CITY,
                    data: '',
                  })
                }}
                prefix={
                  state.addressResidenceCountry === '398'
                    ? 'urResidenceAddressRegion' :
                    state.addressResidenceCountry === '643'
                    ? 'urResidenceAddressRegionRf'
                    : undefined
                }
              />
            ) : (
              <TextInput
                testName="addressResidenceRegion"
                testErrorName="error-addressResidenceRegion"
                disabled={!state.agreed || isResidenceAddressDisabled}
                lang={lang}
                content={accountForm.addressRegion}
                onChange={addressResidenceRegion => {
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_REGION,
                    data: addressResidenceRegion,
                  })
                }}
                valid={
                  state.addressResidenceRegion.length > 0
                    ? smartValidateString(state.addressResidenceRegion) &&
                      !invalidFields.includes('addressResidenceRegion')
                    : !invalidFields.includes('addressResidenceRegion')
                }
                value={state.addressResidenceRegion}
              />
            )}
          </div>
          <div className="col-4 col-xs-12">
            {/* RESIDENCE ADDRESS CITY */}
            {regionCityResidence ? (
              <SelectInput
                testName={'addressResidenceCity'}
                testErrorName={'error-addressResidenceCity'}
                className={styles.input}
                disabled={!state.addressResidenceRegion || isResidenceAddressDisabled}
                lang={lang}
                noOptionsMessage={accountForm.addressCity.placeholder}
                content={accountForm.addressCity}
                value={state.addressResidenceCity}
                optionValue={{
                  value: state.addressResidenceCity,
                  label: state.addressResidenceCity
                }}
                options={cityResidenceList}
                valid={
                  state.addressResidenceCity.length > 0
                    ? smartValidateString(state.addressResidenceCity) &&
                      !invalidFields.includes('addressResidenceCity')
                    : !invalidFields.includes('addressResidenceCity')
                }
                onChange={addressResidenceCity => {
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_CITY,
                    data: addressResidenceCity,
                  })
                }}
                onCreateOption={addressResidenceCity => {
                  console.log('addressResidenceCity', addressResidenceCity)
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_CITY,
                    data: addressResidenceCity,
                  })
                }}
                prefix={
                  state.addressRegisteredCountry === '398' && ['Алматинская область', 'Алматы облысы', 'Almaty'].includes(state.addressResidenceRegion)
                  ? 'urResidenceAddressCity'
                  : state.addressResidenceCountry === '643' && ['Москва и Московская обл.', 'Мәскеу және Мәскеу облысы', 'Moscow and Moscow region', 'Санкт-Петербург и область', 'Санкт-Петербург және облыс', 'St. Petersburg and the region'].includes(state.addressResidenceRegion)
                  ? 'urResidenceAddressCityRf'
                  : undefined
                }
                isSearchable={true}
                creatable={true}
              />
            ) : (
              <TextInput
                testName="addressResidenceCity"
                testErrorName="error-addressResidenceCity"
                disabled={isResidenceAddressDisabled}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.addressCity}
                onChange={addressResidenceCity => {
                  dispatch({
                    type: actions.ADDRESS_RESIDENCE_CITY,
                    data: addressResidenceCity,
                  })
                }}
                valid={
                  validateString(state.addressResidenceCity, 300) &&
                  !invalidFields.includes('addressResidenceCity')
                }
                value={state.addressResidenceCity}
                required
              />
            )}
          </div>
          <div className="col-4 col-xs-12">
            {/* RESIDENCE ADDRESS STREET */}
            <TextInput
              testName="addressResidenceStreet"
              testErrorName="error-addressResidenceStreet"
              disabled={!state.agreed || isResidenceAddressDisabled}
              lang={lang}
              content={accountForm.addressStreet}
              onChange={addressResidenceStreet => {
                dispatch({
                  type: actions.ADDRESS_RESIDENCE_STREET,
                  data: addressResidenceStreet,
                })
              }}
              valid={
                state.addressResidenceStreet.length > 0
                  ? smartValidateString(state.addressResidenceStreet) &&
                    !invalidFields.includes('addressResidenceStreet')
                  : !invalidFields.includes('addressResidenceStreet')
              }
              value={state.addressResidenceStreet}
            />
          </div>
          <div className="col-1 col-xs-12">
            {/* RESIDENCE ADDRESS BUILDING */}
            <TextInput
              testName="addressResidenceBuilding"
              testErrorName="error-addressResidenceBuilding"
              disabled={!state.agreed || isResidenceAddressDisabled}
              lang={lang}
              content={accountForm.addressBuilding}
              onChange={addressResidenceBuilding => {
                dispatch({
                  type: actions.ADDRESS_RESIDENCE_BUILDING,
                  data: addressResidenceBuilding,
                })
              }}
              valid={
                validateString(state.addressResidenceBuilding, 300) &&
                !invalidFields.includes('addressResidenceBuilding')
              }
              value={state.addressResidenceBuilding}
              required
            />
          </div>
          <div className="col-1 col-xs-12">
            {/* RESIDENCE ADDRESS APARTMENTS */}
            <TextInput
              testName="addressResidenceApartments"
              testErrorName="error-addressResidenceApartments"
              disabled={!state.agreed || isResidenceAddressDisabled}
              lang={lang}
              content={accountForm.F_Office_Number}
              onChange={addressResidenceApartments => {
                dispatch({
                  type: actions.ADDRESS_RESIDENCE_APARTMENTS,
                  data: addressResidenceApartments,
                })
              }}
              valid={
                validateString(state.addressResidenceApartments, 50) &&
                !invalidFields.includes('addressResidenceApartments')
              }
              required
              value={state.addressResidenceApartments}
            />
          </div>
          <div className="col-2 col-xs-12">
            {/* RESIDENCE ADDRESS APARTMENTS */}
            <TextInput
              testName="addressResidencePostCode"
              testErrorName="error-addressResidencePostCode"
              disabled={!state.agreed || isResidenceAddressDisabled}
              lang={lang}
              content={accountForm.F_Adress_Index}
              onChange={addressResidencePostCode => {
                dispatch({
                  type: actions.ADDRESS_RESIDENCE_POSTCODE,
                  data: addressResidencePostCode.replace(/_/gim, ''),
                })
              }}
              mask={'*******'}
              valid={
                validateString(state.addressResidencePostCode, 50) &&
                !invalidFields.includes('addressResidencePostCode')
              }
              required
              value={state.addressResidencePostCode}
            />
          </div>
        </div>
      </div> : null }
    </>
  )
}

import { validate as emailValidator } from 'email-validator'
import { parseDate, yearsAgo } from '../../../utils/helpers'
import {
  State,
  RUSSIAN_PASSPORT,
  profileType_Fiscal,
} from '../../../state-actions-reducers'
import { allFiscalCountriesRf } from '../../../content/countries'
import { Option } from '../../../types/common-types'
import { validatePhone } from '../../validators'

interface VlidateDateArgs {
  current: Date | string
  min?: Date
  max?: Date
  required?: boolean
}

export function validateDate({
  current,
  min,
  max,
  required,
}: VlidateDateArgs): boolean {
  // By default allow empty strings. Make stronger checking on submitting
  const currentValue = parseDate(current)
  if (!currentValue) {
    return !required
  }
  if (min && max) {
    return min <= currentValue && currentValue <= max
  }
  if (min) {
    return min <= currentValue
  }
  if (max) {
    return currentValue <= max
  }
  return true
}

export function validateDatesRange(from: Date | string, to: Date | string) {
  const smallerDate = parseDate(from)
  const biggerDate = parseDate(to)
  if (!smallerDate || !biggerDate) {
    return false
  }
  return smallerDate < biggerDate
}

export function validateCollegeDates(
  startDate: string | Date,
  endDate: string | Date,
  required = false
) {
  if (!startDate || !endDate) return !required
  // Check if dates is valid
  return validateDatesRange(startDate, endDate)
}

// By default allow empty strings
// Will make stronger validation on submitting
export function validateString(
  string: string,
  maxLength: number,
  required?: boolean,
  regexp?: RegExp
): boolean {
  const minLength = required ? 1 : 0
  const trimmedString = string.trim()
  return (
    trimmedString.length >= minLength &&
    trimmedString.length <= maxLength &&
    Boolean(trimmedString.match(regexp || ''))
  )
}

export function validateAddress(name: string, required?: boolean): boolean {
  return validateString(name, 200, required)
}

export function validateBankAccount(name: string, required?: boolean): boolean {
  return validateString(name, 200, required)
}

export function validateName(name: string, required?: boolean): boolean {
  return validateString(name, 200, required)
}

export function birthPlace(place: string, required?: boolean): boolean {
  return validateString(place, 300, required)
}

export function stringDigitsLength(
  digits: string,
  digitslength: number,
  required = true
): boolean {
  return (
    validateString(digits, 100, false) &&
    ((required && digits.length == digitslength) || !required)
  )
}

export function passportDigits(digits: string, required?: boolean): boolean {
  return validateString(digits, 100, required)
}

export function passportText(text: string, required?: boolean): boolean {
  return validateString(text, 400, required)
}

export function validateEmail(text: string, required?: boolean): boolean {
  if (!text && !required) return true
  return validateString(text, 100, required) && emailValidator(text)
}

export function validateReferences(
  values: string[],
  required = false
): boolean {
  if (!required && values.length === 0) return true
  if (required && values.length === 0) return false
  if (values.length < 20) {
    return values.every(value => {
      return validateString(value, 200)
    })
  }
  return false
}

const URL_REGEXP = /^((http(s){0,1}:\/\/(\w|\d)+){1}|\w+(\w|\d)*\.\w)[^\s]*((\w|\d)\.(\w|\d)[^\s]*)*(\w|\d|\/)$/g
export function validateUrl(
  url: string,
  maxLength = 300,
  required = false
): boolean {
  if (!url && !required) return true
  if (url) {
    if (typeof url === 'string') {
      if (url.length < maxLength) {
        return Boolean(url.match(URL_REGEXP))
      }
    }
  }
  return false
}

export function validateSocialLink(value: string, required = false): boolean {
  return validateUrl(value, 300, required)
  return false
}

export function validatePassportNumber(
  value: string,
  required = false,
  minLength?: number,
  maxLength?: number
) {
  return (
    validateString(value, maxLength || 10, required) &&
    value.replace(/[^a-zA-Zа-яА-Я0-9 ]/g, '').length >= (minLength || 7)
  )
}

export function smartValidateString(string: string): boolean {
  const trimmedString = string.trim()
  const letter = trimmedString.match(/[a-zA-Zа-яёА-ЯЁ]/g)?.length ?? 0
  const nums = trimmedString.match(/[0-9]/g)?.length ?? 0
  const symbols =
    trimmedString.match(/<>@!#$%^&*()_+[]{}?:;|\'"\/\/\,.~`-='/)?.length ?? 0
  console.log('symbols', symbols)
  return (
    /[a-zA-Zа-яёА-ЯЁ]/g.test(trimmedString) &&
    symbols <= letter * 2 &&
    nums <= letter * 4
  )
}

export function validateStepIndividualRus(
  step: number,
  state: State
): string[] {
  let selectedCitizenship, countryRegistration
  allFiscalCountriesRf.map((country: Option) => {
    if (country.value === state.citizenship)
      selectedCitizenship = country.label.rus
    if (country.value === state.countryRegistration)
      countryRegistration = country.label.rus
  })
  switch (step) {
    case 1:
      let citizenshipFields = []
      if (selectedCitizenship === 'Россия') {
        citizenshipFields.push(
          !validatePassportNumber(state.passportNumber, true, 10, 11) &&
            'passportNumber',
          !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
          !validateDate({
            current: state.passportIAt,
            min: yearsAgo(130),
            max: new Date(),
            required: true,
          }) && 'passportIAt',
          !validatePassportNumber(state.passportAuthorityCode, true, 6, 7) &&
            'passportAuthorityCode',
          state.F_Scan_Directors_Passport.length === 0 &&
            'F_Scan_Directors_Passport',
          state.F_Scan_Directors_Passport_Address.length === 0 &&
            'F_Scan_Directors_Passport_Address'
          // !state.passportDateBoardCheck && 'passportDateBoardCheck'
        )
      }
      if (selectedCitizenship === 'Кыргызстан') {
        citizenshipFields.push(
          !validatePassportNumber(state.passportNumber, false, 10, 11) &&
            'passportNumber',
          !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
          !validateDate({
            current: state.passportIAt,
            min: yearsAgo(130),
            max: new Date(),
            required: true,
          }) && 'passportIAt',
          !validatePassportNumber(
            state.passportAuthorityCode,
            false,
            6,
            7
          ) && 'passportAuthorityCode',
          state.F_Scan_Directors_Passport.length === 0 &&
            'F_Scan_Directors_Passport',
          state.F_Scan_Directors_Passport_Address.length === 0 &&
            'F_Scan_Directors_Passport_Address'
        )
      }
      if (selectedCitizenship === 'Румыния') {
        citizenshipFields.push(
          !validateString(state.passportCardNumber, 30, true) &&
            'passportCardNumber',
          !validateDate({
            current: state.passportExpiryDate,
            min: new Date(),
            max: new Date(
              new Date().setFullYear(new Date().getFullYear() + 10)
            ),
            required: true,
          }) && 'passportExpiryDate',
          state.F_Scan_Directors_Passport.length === 0 &&
            'F_Scan_Directors_Passport',
          state.F_Scan_Directors_Passport_Address.length === 0 &&
            'F_Scan_Directors_Passport_Address'
        )
      }
      if (selectedCitizenship === 'Китай') {
        citizenshipFields.push(
          !validatePassportNumber(state.passportNumber, false, 10, 11) &&
            'passportNumber',
          !validateString(state.passportCardNumber, 30, true) &&
            'passportCardNumber',
          !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
          !validateDate({
            current: state.passportIAt,
            min: yearsAgo(130),
            max: new Date(),
            required: true,
          }) && 'passportIAt',
          !validatePassportNumber(
            state.passportAuthorityCode,
            false,
            6,
            7
          ) && 'passportAuthorityCode',
          !validateDate({
            current: state.passportExpiryDate,
            min: new Date(),
            max: new Date(
              new Date().setFullYear(new Date().getFullYear() + 10)
            ),
            required: true,
          }) && 'passportExpiryDate',
          state.F_Scan_Directors_Passport.length === 0 &&
            'F_Scan_Directors_Passport',
          state.F_Scan_Directors_Passport_Address.length === 0 &&
            'F_Scan_Directors_Passport_Address'
        )
      }
      if (selectedCitizenship === 'Казахстан') {
        citizenshipFields.push(
          !validateString(state.passportCardNumber, 30, true) &&
            'passportCardNumber',
          !validateString(state.passportPersonNumber, 30, true) &&
            'passportPersonNumber',
          !validateDate({
            current: state.passportExpiryDate,
            min: new Date(),
            max: new Date(
              new Date().setFullYear(new Date().getFullYear() + 10)
            ),
            required: true,
          }) && 'passportExpiryDate',
          state.F_Scan_Directors_Passport.length === 0 &&
            'F_Scan_Directors_Passport',
          state.F_Scan_Directors_Passport_Address.length === 0 &&
            'F_Scan_Directors_Passport_Address'
        )
      }
      if (
        selectedCitizenship === 'Бразилия' ||
        selectedCitizenship === 'Италия' ||
        selectedCitizenship === 'Беларусь'
      ) {
        citizenshipFields.push(
          !validatePassportNumber(state.passportNumber, false, 10, 11) &&
            'passportNumber',
          !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
          !validateDate({
            current: state.passportIAt,
            min: yearsAgo(130),
            max: new Date(),
            required: true,
          }) && 'passportIAt',
          state.F_Scan_Directors_Passport.length === 0 &&
            'F_Scan_Directors_Passport',
          state.F_Scan_Directors_Passport_Address.length === 0 &&
            'F_Scan_Directors_Passport_Address'
        )
      }
      if (
        selectedCitizenship === 'Австралия' ||
        selectedCitizenship === 'Азербайджан' ||
        selectedCitizenship === 'Армения' ||
        selectedCitizenship === 'Соединенное Королевство' ||
        selectedCitizenship === 'Германия' ||
        selectedCitizenship === 'Испания' ||
        selectedCitizenship === 'Сирийская Арабская Республика' ||
        selectedCitizenship === 'Соединенные Штаты' ||
        selectedCitizenship === 'Таджикистан' ||
        selectedCitizenship === 'Узбекистан' ||
        selectedCitizenship === 'Франция' ||
        selectedCitizenship === 'Швейцария'
      ) {
        citizenshipFields.push(
          !validatePassportNumber(state.passportNumber, false, 10, 11) &&
            'passportNumber',
          !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
          !validateDate({
            current: state.passportIAt,
            min: yearsAgo(130),
            max: new Date(),
            required: true,
          }) && 'passportIAt',
          !validateDate({
            current: state.passportExpiryDate,
            min: new Date(),
            max: new Date(
              new Date().setFullYear(new Date().getFullYear() + 10)
            ),
            required: true,
          }) && 'passportExpiryDate',
          state.F_Scan_Directors_Passport.length === 0 &&
            'F_Scan_Directors_Passport',
          state.F_Scan_Directors_Passport_Address.length === 0 &&
            'F_Scan_Directors_Passport_Address'
        )
      }
      if (
        selectedCitizenship === 'Болгария' ||
        selectedCitizenship === 'Израиль' ||
        selectedCitizenship === 'Исландия' ||
        selectedCitizenship === 'Молдова, Республика' ||
        selectedCitizenship === 'Нидерланды' ||
        selectedCitizenship === 'Норвегия' ||
        selectedCitizenship === 'Швеция' ||
        selectedCitizenship === 'Южная Африка' ||
        selectedCitizenship === 'Вьетнам' ||
        selectedCitizenship === 'Грузия' ||
        selectedCitizenship === 'Индонезия' ||
        selectedCitizenship === 'Индия' ||
        selectedCitizenship === 'Корея, Республика' ||
        selectedCitizenship === 'Финляндия'
      ) {
        citizenshipFields.push(
          !validatePassportNumber(state.passportNumber, false, 10, 11) &&
            'passportNumber',
          citizenshipFields.push(
            !validateString(state.passportPersonNumber, 30, true) &&
              'passportPersonNumber'
          ),
          !smartValidateString(state.passportIssuedBy) && 'passportIssuedBy',
          !validateDate({
            current: state.passportIAt,
            min: yearsAgo(130),
            max: new Date(),
            required: true,
          }) && 'passportIAt',
          !validateDate({
            current: state.passportExpiryDate,
            min: new Date(),
            max: new Date(
              new Date().setFullYear(new Date().getFullYear() + 10)
            ),
            required: true,
          }) && 'passportExpiryDate',
          state.F_Scan_Directors_Passport.length === 0 &&
            'F_Scan_Directors_Passport',
          state.F_Scan_Directors_Passport_Address.length === 0 &&
            'F_Scan_Directors_Passport_Address'
        )
      }
      return [
        // // Array of invalid field names
        // // validate(): boolean && 'field name' -> null or 'field name'
        // // Then this array filtered with the Boolean()
        // !validateString(state.mainCity, 100, true) && 'mainCity',
        !validateName(state.F_FiscalType, true) && 'F_FiscalType',
        !validateName(state.headFirstname, true) && 'headFirstname',
        !validateName(state.headLastname, true) && 'headLastname',
        !validateName(state.headPatronymic, true) &&
          !state.noPatronymic &&
          'headPatronymic',
        !validateDate({
          current: state.registeredDate,
          required: true,
        }) && 'registeredDate',
        !stringDigitsLength(state.F_INN, 12) && 'F_INN',
        !stringDigitsLength(state.certificateRegistrationNumber, 11) && 'certificateRegistrationNumber',
        // state.F_INN_Image.length === 0 && 'F_INN_Image',
        !stringDigitsLength(state.F_OGRNIP, 15) && 'F_OGRNIP',
        // state.F_OGRN_Image.length === 0 && 'F_OGRN_Image',
        state.F_EGRUL_Image.length === 0 && 'F_EGRUL_Image',
        state.F_Rent_Contract_Image.length === 0 &&
          !state.noLeaseAgreement &&
          'F_Rent_Contract_Image',
        !validateString(state.addressFactualCountry, 300, true) &&
          'addressFactualCountry',
        !validateString(state.addressFactualRegion, 300, true) &&
          'addressFactualRegion',
        !validateString(state.addressFactualCity, 300, true) &&
          'addressFactualCity',
        !validateString(state.addressFactualStreet, 300, true) &&
          'addressFactualStreet',
        !validateString(state.addressFactualBuilding, 300, true) &&
          'addressFactualBuilding',
        !validateString(state.addressFactualApartments, 300, true) &&
          !state.addressFactualNoApartments &&
          'addressFactualApartments',
          
        !validateString(state.addressRegisteredPostCode, 50, true) &&
          'addressRegisteredPostCode',
        !validateString(state.addressRegisteredCountry, 300, true) &&
          'addressRegisteredCountry',
        !validateString(state.addressRegisteredRegion, 300, true) &&
          'addressRegisteredRegion',
        !validateString(state.addressRegisteredCity, 300, true) &&
          'addressRegisteredCity',
        !validateString(state.addressRegisteredStreet, 300, true) &&
          'addressRegisteredStreet',
        !validateString(state.addressRegisteredBuilding, 300, true) &&
          'addressRegisteredBuilding',
        !validateString(state.addressRegisteredApartments, 300, true) &&
          !state.addressRegisteredNoApartments &&
          'addressRegisteredApartments',
        !validateString(state.addressFactualPostCode, 50, true) &&
          'addressFactualPostCode',
        
        !validateString(state.addressResidenceCountry, 300, true) &&
          'addressResidenceCountry',
        !validateString(state.addressResidenceRegion, 300, true) &&
          'addressResidenceRegion',
        !validateString(state.addressResidenceCity, 300, true) &&
          'addressResidenceCity',
        !validateString(state.addressResidenceStreet, 300, true) &&
          'addressResidenceStreet',
        !validateString(state.addressResidenceBuilding, 300, true) &&
          'addressResidenceBuilding',
        !validateString(state.addressResidenceApartments, 300, true) &&
          !state.addressResidenceNoApartments &&
          'addressResidenceApartments',
        !validateString(state.addressResidencePostCode, 50, true) &&
          'addressResidencePostCode',

        !validateDate({
          current: state.birthDate,
          min: yearsAgo(130),
          max: yearsAgo(14),
          required: true,
        }) && 'birthDate',
        !validateString(state.birthPlace, 300, true) && 'birthPlace',
        // !validateString(state.sex, 10, true) && 'sex',
        ...citizenshipFields,
      ].filter(Boolean) as string[]
    case 2:
      return [
        state.BankDetails.some(
          bank => !stringDigitsLength(bank.F_BankBIK, 9)
        ) && 'F_BankBIK',
        state.BankDetails.some(
          bank => !stringDigitsLength(bank.F_BankRS, 20)
        ) && 'F_BankRS',
        state.BankDetails.some(
          bank => !stringDigitsLength(bank.F_BankKS, 20)
        ) && 'F_BankKS',
        state.BankDetails.some(bank => !validateName(bank.F_BankName, true)) &&
          'F_BankName',
      ].filter(Boolean) as string[]
    case 3:
      return [
        !state.contactPhones.some(number => validatePhone(number)) &&
          'contactPhones',
        !state.contactEmails.some(contactEmails =>
          validateEmail(contactEmails, true)
        ) && 'contactEmails',
        !validateString(state.purposeOfLease, 300, true) && 'purposeOfLease',
        !state.messengers.some(messenger =>
          validateString(messenger.type, 200, true)
        ) && 'messengersType',
        !state.messengers.some(messenger =>
          validateString(messenger.link, 200, true)
        ) && 'messengersLink',
        !state.additionalLinksDescription.some(desc =>
          validateString(desc, 300, true)
        ) && 'additionalLinksDescription',
        !state.additionalLinks.some(links =>
          validateString(links, 300, true)
        ) && 'additionalLinks',
        !validateName(state.contactPersonFirstname, true) &&
          'contactPersonFirstname',
        !validateName(state.contactPersonLastname, true) &&
          'contactPersonLastname',
        !validateName(state.contactPersonPatronymic, true) &&
          !state.noPatronymicContact &&
          'contactPersonPatronymic',
        !state.contactPersonPhones.some(number => validatePhone(number)) &&
          'contactPersonPhones',
        !state.contactPersonEmails.some(contactPersonEmails =>
          validateEmail(contactPersonEmails, true)
        ) && 'contactPersonEmails',
        !validateString(state.contactPersonPosition, 300, true) &&
          'contactPersonPosition',
      ].filter(Boolean) as string[]
    case 4:

    default:
      return []
  }
}

export function validateForm(state: State): string[] {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].reduce(
    (result: string[], step: number): string[] => [
      ...result,
      ...validateStepIndividualRus(step, state),
    ],
    []
  )
}

export function trimForm(state: State): State {
  return Object.keys(state).reduce((trimmedState: State, key) => {
    switch (key) {
      case 'mobilePhoneNumbers':
      case 'F_WorkPhoneNumber':
      case 'contactPhones':
      case 'addressRegisteredPhones':
      case 'addressFactualPhones':
      case 'workPhoneNumbers':
        // Filter out invalid values
        return {
          ...trimmedState,
          [key]: state[key].filter(validatePhone),
        }
      case 'emails':
        return {
          ...trimmedState,
          emails: state.emails.filter(email => validateEmail(email, true)),
        }
      case 'F_Email':
        return {
          ...trimmedState,
          emails: state.emails.filter(email => validateEmail(email, true)),
        }
      case 'vk':
      case 'facebook':
      case 'youtube':
      case 'vimeo':
      case 'instagram':
      case 'workWebsite':
      case 'personalWebsite':
      case 'additionalLinks':
      case 'addressRegisteredDate':
        return {
          ...trimmedState,
          addressRegisteredDate: validateDate({
            current: state.addressRegisteredDate,
            max: new Date(),
          })
            ? state.addressRegisteredDate
            : '',
        }
      case 'isStudent':
        return {
          ...trimmedState,
          collegeStartedAt: state.isStudent ? state.collegeStartedAt : '',
          collegeEndedAt: state.isStudent ? state.collegeEndedAt : '',
        }
      default:
        return trimmedState
    }
  }, state)
}

import axios from 'axios';
import { getSavedOrderId, saveOrderId } from '../local-storage';
import { API_URL } from '../server-parameters';
import { State } from '../state-actions-reducers';

// Создаем экземпляр axios
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'text/plain',
  },
});

// Интерцептор для добавления токена в заголовки
api.interceptors.request.use((config) => {
  if (typeof window !== 'undefined') {
    const orderToken = getSavedOrderId(window.localStorage);
    console.log('Order Token from localStorage:', orderToken);
    if (orderToken) {
      config.headers.Authorization = `Bearer ${orderToken}`;
    }
  }
  return config;
});

// Интерцептор для обработки ошибок и обновления токена
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Если ошибка связана с истекшим или недействительным токеном
    if (
      (error.response?.status === 400 ||
        error.response?.status === 401 ||
        error.response?.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true; // Помечаем запрос как повторный

      // Получаем новый токен
      const newOrderToken = getSavedOrderId(window.localStorage);
      if (newOrderToken) {
        saveOrderId(newOrderToken, window.localStorage); // Обновляем токен в localStorage
        originalRequest.headers.Authorization = `Bearer ${newOrderToken}`; // Обновляем заголовок
        return api(originalRequest); // Повторяем запрос с новым токеном
      }
    }

    return Promise.reject(error);
  }
);

// Основная функция для отправки заказа
export async function useOrderSubmit(
  form: State,
  startDate: any,
  endDate: any,
  goods: any,
  localStorage: Storage
) {
  const formData = {
    start: startDate,
    end: endDate,
    goods: goods,
    user: {
      lastName: form.lastName,
      firstName: form.firstName,
      patronymic: form.patronymic,
      phone: form.mobilePhoneNumbers[0],
      email: form.emails[0],
    },
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: '',
  };

  try {
    const response = await api.post('/Orders/v2', formData);
    return response.data;
  } catch (error) {
    console.error('Error submitting order:', error);
    throw error;
  }
}
import React, { createContext, useContext, useState, useEffect } from 'react'

import ContextCurrentLocale from '../components/ContextCurrentLocale'
import AccountForm from '../components/IndexPresentationComponent'

declare global {
  interface Window {
    iFrameResizer: any
    parentIFrame: any
  }
}

const QueryContext = createContext<string | undefined>(undefined)

export const useQueryContext = () => {
  const query = useContext(QueryContext)
  if (query === undefined) {
    throw new Error(
      'To use the url query context you must wrap component with the QueryContextProvider'
    )
  }
  return query
}

export default function IndexPage() {
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setQuery(window.location.search);

      const handleLocationChange = () => {
        setQuery(window.location.search);
      };

      window.addEventListener('popstate', handleLocationChange);

      return () => {
        window.removeEventListener('popstate', handleLocationChange);
      };
    }
  }, []);

  return (
    <QueryContext.Provider value={query}>
        {/* <div style={{fontSize: '20px', color:"red"}}> Тестовая версия! Отправка анкет не работает!!!</div> 
        Для отправки анкеты воспользуйтесь <a href="https://anketa.rentaphoto.com/">формой</a> */}
        <ContextCurrentLocale.Provider value="rus">
          <AccountForm />
        </ContextCurrentLocale.Provider>
    </QueryContext.Provider>
  )
}

import React, { useState } from 'react'
import TextInput from '../../../../components/Inputs/TextInput'
import DatePicker from '../../../../components/Inputs/DatePicker'
import { UPLOADS_ENDPOINT } from '../../../../server-parameters'
import { yearsAgo } from '../../../../utils/helpers'
import {
  validateName,
  validateDate,
  passportDigits,
  passportText,
  smartValidateString,
} from '../../../../validate/validators'
import { Country, ScreenProps } from '../../../../types/common-types'
import { actions } from '../../../../state-actions-reducers'
import FileInput from '../../../../components/Inputs/FileInput'
import { getPassportTypeTranslate } from '../../../../hooks/useGetPassportTypeTranslate'
import IdentityExist from '../../../../components/IdentityExist'

export default function GermanyForm({
  state,
  lang,
  invalidFields,
  dispatch,
  accountForm,
}: ScreenProps) {
  const [focus, setFocus] = useState('')
  return (
    <>
      {getPassportTypeTranslate(+state.passportType) === 'Паспорт' ? (
        <>
          <div className="col-2 col-xs-12">
            {/* PASSPORT SERIES / NUMBER */}
            <TextInput
              testName="passportNumber"
              testErrorName="error-passportNumber"
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.documentNumberBel}
              onChange={passportNumber => {
                dispatch({
                  type: actions.PASSPORT_NUMBER,
                  data: passportNumber,
                })
              }}
              value={state.passportNumber}
              required
              valid={
                passportDigits(state.passportNumber) &&
                !invalidFields.includes('passportNumber')
              }
              onBlur={() => setFocus('')}
              mask={'aa 999999'}
            />
          </div>
          <IdentityExist
            state={state}
            accountForm={accountForm}
            lang={lang}
            dispatch={dispatch}
            show={!state.isIdentityOk}
          />
          <div className="col-2 col-xs-12">
            {/* PASSPORT ISSUE DATE */}
            <DatePicker
              testName="passportIAt"
              testErrorName="error-passportIAt"
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportIAt"
              onChange={data => dispatch({ type: actions.PASSPORT_IAT, data })}
              value={state.passportIAt}
              required
              valid={
                !invalidFields.includes('passportIAt') &&
                validateDate({
                  current: state.passportIAt,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              curr={
                state.birthDate
                  ? new Date(
                    new Date(state.birthDate).setFullYear(
                      new Date(state.birthDate).getFullYear() + 14
                    )
                  )
                  : new Date()
              }
              min={
                state.birthDate &&
                  new Date(state.birthDate).getFullYear() + 14 >
                  new Date().getFullYear() - 10
                  ? new Date(
                    new Date(state.birthDate).setFullYear(
                      new Date(state.birthDate).getFullYear() + 14
                    )
                  )
                  : new Date(
                    new Date().setFullYear(new Date().getFullYear() - 10)
                  )
              }
              max={new Date()}
            />
          </div>
          <div className="col-8 col-xs-12">
            {/* PASSPORT ISSUED BY */}
            <TextInput
              testName="passportIssuedBy"
              testErrorName="error-passportIssuedBy"
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.issuedBy}
              onChange={passportIssuedBy =>
                dispatch({
                  type: actions.PASSPORT_ISSUED_BY,
                  data: passportIssuedBy.toLocaleUpperCase(),
                })
              }
              value={state.passportIssuedBy}
              required
              valid={
                state.passportIssuedBy.length > 0
                  ? smartValidateString(state.passportIssuedBy) &&
                  !invalidFields.includes('passportIssuedBy')
                  : !invalidFields.includes('passportIssuedBy')
              }
              onBlur={() => setFocus('')}
            />
          </div>
          <div className="col-3 col-xs-12">
            {/* PASSPORT DATE BOARDING*/}
            <DatePicker
              testName="passportDateBoardCrossing"
              testErrorName="error-passportDateBoardCrossing"
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportDateBoardCrossing"
              onChange={data =>
                dispatch({
                  type: actions.PASSPORT_DATE_BOARD_CROSSING,
                  data,
                })
              }
              value={state.passportDateBoardCrossing}
              required
              valid={
                !invalidFields.includes('passportDateBoardCrossing') &&
                validateDate({
                  current: state.passportDateBoardCrossing,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              max={new Date()}
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName="mainImageFile"
              testErrorName="error-mainImageFile"
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.passportImages}
              content={accountForm.mainImageFile}
              dispatch={dispatch}
              onChange={data => {
                dispatch({
                  type: actions.MAIN_IMAGE_FILE,
                  data: data,
                })
              }}
              value={state.mainImageFile[state.mainImageFile.length - 1]}
              valid={!invalidFields.includes('mainImageFile')}
              documentType="Passport1"
              imageErrors={state.imageError}
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName="addressImageFile"
              testErrorName="error-addressImageFile"
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.passportImages}
              content={accountForm.arrivalStampFile}
              dispatch={dispatch}
              onChange={addressImageFile => {
                dispatch({
                  type: actions.ADDRESS_IMAGE_FILE,
                  data: addressImageFile,
                })
              }}
              value={state.addressImageFile[state.addressImageFile.length - 1]}
              valid={!invalidFields.includes('addressImageFile')}
              documentType="Passport2"
              imageErrors={state.imageError}
            />
          </div>
        </>
      ): getPassportTypeTranslate(+state.passportType) === 'Загран. паспорт' ? (
        <>
          <div className="col-3 col-xs-12">
            {/* PASSPORT INTERNETIONAL NUMBER */}
            <TextInput
              testName="passportInternationalNumber"
              testErrorName="error-passportInternationalNumber"
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.documentNumberBel}
              onChange={passportInternationalNumber => {
                dispatch({
                  type: actions.PASSPORT_INTERNATIONAL_NUMBER,
                  data: passportInternationalNumber,
                })
              }}
              value={state.passportInternationalNumber}
              required
              valid={
                passportDigits(state.passportInternationalNumber) &&
                !invalidFields.includes('passportInternationalNumber')
              }
              onBlur={() => setFocus('')}
              mask={'aa 999999'}
            />
          </div>
          <IdentityExist
            state={state}
            accountForm={accountForm}
            lang={lang}
            dispatch={dispatch}
            show={!state.isIdentityOk}
          />
          <div className="col-2 col-xs-12">
            {/* PASSPORT ISSUE DATE */}
            <DatePicker
              testName="passportIAt"
              testErrorName="error-passportIAt"
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportIAt"
              onChange={data => dispatch({ type: actions.PASSPORT_IAT, data })}
              value={state.passportIAt}
              required
              valid={
                !invalidFields.includes('passportIAt') &&
                validateDate({
                  current: state.passportIAt,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              curr={new Date()}
              min={
                new Date(new Date().setFullYear(new Date().getFullYear() - 10))
              }
              max={new Date()}
            />
          </div>
          <div className="col-8 col-xs-12">
            {/* PASSPORT ISSUED BY */}
            <TextInput
              testName="passportIssuedBy"
              testErrorName="error-passportIssuedBy"
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.issuedBy}
              onChange={passportIssuedBy =>
                dispatch({
                  type: actions.PASSPORT_ISSUED_BY,
                  data: passportIssuedBy.toLocaleUpperCase(),
                })
              }
              value={state.passportIssuedBy}
              required
              valid={
                !invalidFields.includes('passportIssuedBy')
              }
              onBlur={() => setFocus('')}
            />
          </div>
          <div className="col-3 col-xs-12">
            {/* PASSPORT EXPIRY DATE */}
            <DatePicker
              testName="passportExpiryDate"
              testErrorName="error-passportExpiryDate"
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportExpiryDate"
              onChange={data =>
                dispatch({ type: actions.PASSPORT_EXPIRY_DATE, data })
              }
              value={state.passportExpiryDate}
              required
              valid={
                !invalidFields.includes('passportExpiryDate') &&
                validateDate({
                  current: state.passportExpiryDate,
                  min: new Date(),
                  max: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 10)
                  ),
                })
              }
              onBlur={() => setFocus('')}
              min={new Date()}
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() + 10))
              }
            />
          </div>
          <div className="col-3 col-xs-12">
            {/* PASSPORT DATE BOARDING*/}
            <DatePicker
              testName="passportDateBoardCrossing"
              testErrorName="error-passportDateBoardCrossing"
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportDateBoardCrossing"
              onChange={data =>
                dispatch({
                  type: actions.PASSPORT_DATE_BOARD_CROSSING,
                  data,
                })
              }
              value={state.passportDateBoardCrossing}
              required
              valid={
                !invalidFields.includes('passportDateBoardCrossing') &&
                validateDate({
                  current: state.passportDateBoardCrossing,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              max={new Date()}
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName="mainImageFile"
              testErrorName="error-mainImageFile"
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.passportImages}
              content={accountForm.internationalFile}
              dispatch={dispatch}
              onChange={mainImageFile => {
                dispatch({
                  type: actions.MAIN_IMAGE_FILE,
                  data: mainImageFile,
                })
              }}
              value={state.mainImageFile[state.mainImageFile.length - 1]}
              valid={!invalidFields.includes('mainImageFile')}
              documentType="Passport1"
              imageErrors={state.imageError}
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName="addressImageFile"
              testErrorName="error-addressImageFile"
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.passportImages}
              content={accountForm.arrivalStampFile}
              dispatch={dispatch}
              onChange={addressImageFile => {
                dispatch({
                  type: actions.ADDRESS_IMAGE_FILE,
                  data: addressImageFile,
                })
              }}
              value={state.addressImageFile[state.addressImageFile.length - 1]}
              valid={!invalidFields.includes('addressImageFile')}
              documentType="Passport2"
              imageErrors={state.imageError}
            />
          </div>
        </>
      ) : getPassportTypeTranslate(+state.passportType) ===
      'Вид на жительство' ? (
      <>
        <div className="col-4 col-xs-12">
          {/* PASSPORT_CARD_NUMBER */}
          <TextInput
            testName="passportPermanentResidencyNumber"
            testErrorName="error-passportPermanentResidencyNumber"
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.documentNumberBel}
            onChange={number => {
              dispatch({
                type: actions.PASSPORT_PERMANENT_RESIDENCY_NUMBER,
                data: number,
              })
            }}
            value={state.passportPermanentResidencyNumber}
            required
            valid={
              passportDigits(state.passportPermanentResidencyNumber) &&
              !invalidFields.includes('passportPermanentResidencyNumber')
            }
            onBlur={() => setFocus('')}
            mask={'99 9999999'}
          />
        </div>
        <IdentityExist
          state={state}
          accountForm={accountForm}
          lang={lang}
          dispatch={dispatch}
          show={!state.isIdentityOk}
        />
        <div className="col-2 col-xs-12">
          {/* PASSPORT PERMANENT RESIDENCY DATE */}
          <DatePicker
            disabled={!state.agreed}
            lang={lang}
            accountForm={accountForm}
            accountFormKey="passportDateIssuePermanentResidency"
            onChange={data =>
              dispatch({
                type: actions.PASSPORT_DATE_ISSUE_PERMANENT_RESIDENCY,
                data,
              })
            }
            value={state.passportDateIssuePermanentResidency}
            required
            valid={
              !invalidFields.includes(
                'passportDateIssuePermanentResidency'
              ) &&
              validateDate({
                current: state.passportDateIssuePermanentResidency,
                min: yearsAgo(130),
                max: new Date(),
              })
            }
            onBlur={() => setFocus('')}
            min={
              state.birthDate
                ? new Date(
                  new Date(state.birthDate).setFullYear(
                    new Date(state.birthDate).getFullYear()
                  )
                )
                : new Date(
                  new Date().setFullYear(new Date().getFullYear() - 67)
                )
            }
            max={new Date()}
          />
        </div>
        <div className="col-8 col-xs-12">
          {/* PASSPORT ISSUED BY */}
          <TextInput
            testName="passportIssuedBy"
            testErrorName="error-passportIssuedBy"
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.issuedBy}
            onChange={passportIssuedBy =>
              dispatch({
                type: actions.PASSPORT_ISSUED_BY,
                data: passportIssuedBy.toLocaleUpperCase(),
              })
            }
            value={state.passportIssuedBy}
            required
            valid={
              state.passportIssuedBy.length > 0
                ? smartValidateString(state.passportIssuedBy) &&
                !invalidFields.includes('passportIssuedBy')
                : !invalidFields.includes('passportIssuedBy')
            }
            onBlur={() => setFocus('')}
          />
        </div>
        <div className="col-4 col-xs-12">
          <FileInput
            disabled={!state.agreed}
            lang={lang}
            url={UPLOADS_ENDPOINT}
            autorization={state.formId}
            target={state.passportImages}
            content={accountForm.residenceFile}
            dispatch={dispatch}
            onChange={residenceImageFile => {
              dispatch({
                type: actions.RESIDENCE_IMAGE_FILE,
                data: residenceImageFile,
              })
            }}
            value={
              state.residenceImageFile[state.residenceImageFile.length - 1]
            }
            valid={!invalidFields.includes('residenceImageFile')}
            documentType="Passport1"
            imageErrors={state.imageError}
          />
        </div>
        <div className="col-4 col-xs-12">
          <FileInput
            testName={'addressImageFile'}
            testErrorName={'error-addressImageFile'}
            disabled={!state.agreed}
            lang={lang}
            url={UPLOADS_ENDPOINT}
            autorization={state.formId}
            target={state.passportImages}
            content={accountForm.residenceBacksideFile}
            dispatch={dispatch}
            onChange={data => {
              dispatch({
                type: actions.ADDRESS_IMAGE_FILE,
                data: data,
              })
            }}
            value={state.addressImageFile[state.addressImageFile.length - 1]}
            valid={!invalidFields.includes('addressImageFile')}
            documentType="Passport2"
            imageErrors={state.imageError}
          />
        </div>
      </>
    ) : getPassportTypeTranslate(+state.passportType) === 'ID карта' ? (
      <>
        <div className="col-4 col-xs-12">
          {/* PASSPORT_CARD_NUMBER */}
          <TextInput
            testName="passportCardNumber"
            testErrorName="error-passportCardNumber"
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.documentNumberGer}
            onChange={passportCardNumber => {
              dispatch({
                type: actions.PASSPORT_CARD_NUMBER,
                data: passportCardNumber,
              })
            }}
            value={state.passportCardNumber}
            required
            valid={!invalidFields.includes('passportCardNumber')}
            onBlur={() => setFocus('')}
            mask={'999999999'}
          />
        </div>
        <IdentityExist
          state={state}
          accountForm={accountForm}
          lang={lang}
          dispatch={dispatch}
          show={!state.isIdentityOk}
        />
        <div className="col-8 col-xs-12">
          {/* PASSPORT ISSUED BY */}
          <TextInput
            testName="passportIssuedBy"
            testErrorName="error-passportIssuedBy"
            disabled={!state.agreed}
            lang={lang}
            content={accountForm.issuedBy}
            onChange={passportIssuedBy =>
              dispatch({
                type: actions.PASSPORT_ISSUED_BY,
                data: passportIssuedBy.toLocaleUpperCase(),
              })
            }
            value={state.passportIssuedBy}
            required
            valid={
              state.passportIssuedBy.length > 0
                ? smartValidateString(state.passportIssuedBy) &&
                !invalidFields.includes('passportIssuedBy')
                : !invalidFields.includes('passportIssuedBy')
            }
            onBlur={() => setFocus('')}
          />
        </div>
        <div className="col-2 col-xs-12">
          {/* PASSPORT ISSUE DATE */}
          <DatePicker
            testName="passportIAt"
            testErrorName="error-passportIAt"
            disabled={!state.agreed}
            lang={lang}
            accountForm={accountForm}
            accountFormKey="passportIAt"
            onChange={data => dispatch({ type: actions.PASSPORT_IAT, data })}
            value={state.passportIAt}
            required
            valid={
              !invalidFields.includes('passportIAt') &&
              validateDate({
                current: state.passportIAt,
                min: yearsAgo(130),
                max: new Date(),
              })
            }
            onBlur={() => setFocus('')}
            curr={
              state.birthDate
                ? new Date(
                  new Date(state.birthDate).setFullYear(
                    new Date(state.birthDate).getFullYear() + 14
                  )
                )
                : new Date()
            }
            min={
              state.birthDate &&
                new Date(state.birthDate).getFullYear() + 14 >
                new Date().getFullYear() - 10
                ? new Date(
                  new Date(state.birthDate).setFullYear(
                    new Date(state.birthDate).getFullYear() + 14
                  )
                )
                : new Date(
                  new Date().setFullYear(new Date().getFullYear() - 10)
                )
            }
            max={new Date()}
          />
        </div>
        <div className="col-3 col-xs-12">
          {/* PASSPORT EXPIRY DATE */}
          <DatePicker
            testName="passportIAt"
            testErrorName="error-passportIAt"
            disabled={!state.agreed}
            lang={lang}
            accountForm={accountForm}
            accountFormKey="passportExpiryDate"
            onChange={data =>
              dispatch({ type: actions.PASSPORT_EXPIRY_DATE, data })
            }
            value={state.passportExpiryDate}
            required
            valid={
              !invalidFields.includes('passportExpiryDate') &&
              validateDate({
                current: state.passportExpiryDate,
                min: new Date(),
                max: new Date(
                  new Date().setFullYear(new Date().getFullYear() + 10)
                ),
              })
            }
            onBlur={() => setFocus('')}
            min={new Date()}
            max={
              new Date(new Date().setFullYear(new Date().getFullYear() + 10))
            }
          />
        </div>
        <div className="col-3 col-xs-12">
          {/* PASSPORT DATE BOARDING*/}
          <DatePicker
            testName="passportDateBoardCrossing"
            testErrorName="error-passportDateBoardCrossing"
            disabled={!state.agreed}
            lang={lang}
            accountForm={accountForm}
            accountFormKey="passportDateBoardCrossing"
            onChange={data =>
              dispatch({
                type: actions.PASSPORT_DATE_BOARD_CROSSING,
                data,
              })
            }
            value={state.passportDateBoardCrossing}
            required
            valid={
              !invalidFields.includes('passportDateBoardCrossing') &&
              validateDate({
                current: state.passportDateBoardCrossing,
                min: yearsAgo(130),
                max: new Date(),
              })
            }
            onBlur={() => setFocus('')}
            max={new Date()}
          />
        </div>
        <div className="col-4 col-xs-12">
          <FileInput
            testName="mainImageFile"
            testErrorName="error-mainImageFile"
            disabled={!state.agreed}
            lang={lang}
            url={UPLOADS_ENDPOINT}
            autorization={state.formId}
            target={state.passportImages}
            content={accountForm.idFile}
            dispatch={dispatch}
            onChange={data => {
              dispatch({
                type: actions.MAIN_IMAGE_FILE,
                data: data,
              })
            }}
            value={state.mainImageFile[state.mainImageFile.length - 1]}
            valid={!invalidFields.includes('mainImageFile')}
            documentType="Passport1"
            imageErrors={state.imageError}
          />
        </div>
        <div className="col-4 col-xs-12">
          <FileInput
            testName="addressImageFile"
            testErrorName="error-addressImageFile"
            disabled={!state.agreed}
            lang={lang}
            url={UPLOADS_ENDPOINT}
            autorization={state.formId}
            target={state.passportImages}
            content={accountForm.residenceBacksideFile}
            dispatch={dispatch}
            onChange={data => {
              dispatch({
                type: actions.ADDRESS_IMAGE_FILE,
                data: data,
              })
            }}
            value={state.addressImageFile[state.addressImageFile.length - 1]}
            valid={!invalidFields.includes('addressImageFile')}
            documentType="Passport2"
            imageErrors={state.imageError}
          />
        </div>
      </>
    ) : null}
    </>
  )
}

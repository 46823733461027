// TODO: Handle server errors. Now user can't proceed if server responded with
// error

import React, { useEffect, useMemo, useState } from 'react'

import { Lang } from '../../types/common-types'

import { State, Dispatch, actions } from '../../state-actions-reducers'
import AccountForm from '../../types/account-form.types'
import TextInput from '../../components/Inputs/TextInput'
import styles from './ScreenThreeCart.module.scss'
import warningImage from '../../images/warning.svg'
import { getProduct } from '../../hooks/useProductsCustomHook'


interface Props {
  lang: Lang
  state: State
  dispatch: Dispatch
  accountForm: AccountForm
  invalidFields: string[]
  cart: any
}

/* THE COMPONENT */
function ScreenThreeCart({
  lang,
  state,
  dispatch,
  accountForm,
  invalidFields,
  cart,
}: Props) {
  const [productsImages, setProductsImages]: any = useState([])
  async function setProductsImagesHandler() {
    let promises:any = []
    cart.forEach(async (item:any) => {
      promises.push(getProduct(item.guid, state))
    });

    let result = await Promise.allSettled(promises)
      .then((data) => {return data})
      .catch((error)=> console.log(error));
    setProductsImages(result?.map((item:any)=> {
      return item?.value?.commodityImagePrimary
    }))
  }
  useEffect(()=> {
    setProductsImagesHandler()
  }, [cart])
  const valuta = cart[0].valuta
  const valutaIconGlobal =
    valuta === 'eur' ? (
      <>&#8364;</>
    ) : valuta === 'usd' ? (
      <>&#36;</>
    ) : valuta === 'cny' ? (
      <>&#65509;</>
    ) : valuta === 'kzt' ? (
      <>&#8376;</>
    ) : (
      <>&#8381;</>
    )
  const CartList = cart.map((item: any, index: number) => {
    let valuteIcon =
      item.valuta === 'eur' ? (
        <>&#8364;</>
      ) : item.valuta === 'usd' ? (
        <>&#36;</>
      ) : item.valuta === 'cny' ? (
        <>&#65509;</>
      ) : item.valuta === 'kzt' ? (
        <>&#8376;</>
      ) : (
        <>&#8381;</>
      )
    let price =
      +item.valutaRate > 0 ? +item.price / +item.valutaRate : +item.price
    return (
      <tr key={index}>
        <td className={styles.table__img}>
          <img src={state.iframeCountry === 'ala' ? item.img : 'data:image/png; base64, ' + productsImages[index]} alt="image" />
        </td>
        <td align="left" className={styles.table__name}>
          {item.name}
        </td>
        <td align="center">x{item.count}</td>
        <td align="center" className={styles.table__date}>
          <div>
            <span>{item.startDate}</span>
            <span>{item.startTime}</span>
          </div>
          <div>
            <span>{item.endDate}</span>
            <span>{item.endTime}</span>
          </div>
        </td>
        <td align="center">x{item.shift}</td>
        <td align="center" className={styles.table__discount}>
        <div>{Math.round(item.discount.toLocaleString())}%</div>
        </td>
        <td align="center">
          {item.discount > 0 ? (
            <>
              <span className={styles.table__oldPrice}>
                {Math.round(price * item.count * item.shift).toLocaleString()} {valuteIcon}
              </span>
              <span className={styles.table__newPrice}>
                {Math.round(
                  ((price * (100 - item.discount)) / 100) *
                    item.count *
                    item.shift
                ).toLocaleString()}{' '}
                {valuteIcon}
              </span>
            </>
          ) : (
            <span>
              {(Math.round(price) * item.count * item.shift).toLocaleString()} {valuteIcon}
            </span>
          )}
        </td>
      </tr>
    )
  })
  const CartListMobile = cart.map((item: any, index: number) => {
    let valuteIcon =
      item.valuta === 'eur' ? (
        <>&#8364;</>
      ) : item.valuta === 'usd' ? (
        <>&#36;</>
      ) : item.valuta === 'cny' ? (
        <>&#65509;</>
      ) : item.valuta === 'kzt' ? (
        <>&#8376;</>
      ) : (
        <>&#8381;</>
      )
    let price =
      +item.valutaRate > 0 ? +item.price / +item.valutaRate : +item.price
    return (
      <div key={index} className={styles.ThreeCartMobileTable__item}>
        <div className={styles.ThreeCartMobileTable__item_img}>
          <img src={item.img} alt="image" />
        </div>
        <div className={styles.ThreeCartMobileTable__item_col}>
          <div className={styles.ThreeCartMobileTable__item_options}>
            <div className={styles.ThreeCartMobileTable__item_options_price}>
              {item.discount > 0 ? (
                <>
                  <span
                    className={
                      styles.ThreeCartMobileTable__item_options_oldPrice
                    }
                  >
                    {Math.round(price * item.count * item.shift).toLocaleString()} {valuteIcon}
                  </span>
                  <span
                    className={
                      styles.ThreeCartMobileTable__item_options_newPrice
                    }
                  >
                    {Math.round(
                      ((price * (100 - item.discount)) / 100) *
                        item.count *
                        item.shift
                    ).toLocaleString()}{' '}
                    {valuteIcon}
                  </span>
                </>
              ) : (
                <span
                  className={styles.ThreeCartMobileTable__item_options_newPrice}
                >
                  {Math.round(price).toLocaleString()} {valuteIcon}
                </span>
              )}
            </div>
            {item.discount > 0 ? (
              <div
                className={styles.ThreeCartMobileTable__item_options_discount}
              >
                {Math.round(item.discount).toLocaleString()}%
              </div>
            ) : null}
            <div className={styles.ThreeCartMobileTable__item_options_shift}>
              {item.shift} смена
            </div>
          </div>
          <p>{item.name}</p>
          <div className={styles.ThreeCartMobileTable__item_dates}>
            <span className={styles.ThreeCartMobileTable__item_dates_header}>
              Срок аренды:
            </span>
            <div>
              <span>
                {item.startDate} {item.startTime} - {item.endDate}{' '}
                {item.endTime}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  })
  console.log(
    cart.map(item => {
      return item.price
    })
  )
  const allSum =
    cart.length > 1
      ? cart.reduce((prev: any, cur: any) => {
          let curPrice =
            +cur.valutaRate > 0 ? +cur.price / +cur.valutaRate : +cur.price
          return (+prev || 0) + +curPrice * +cur.shift * +cur.count
        }, 0)
      : +cart[0].valutaRate > 0
      ? (+cart[0].price / +cart[0].valutaRate) * +cart[0].shift * +cart[0].count
      : +cart[0].price * +cart[0].shift * +cart[0].count
  const discountSum =
    cart.length > 1
      ? allSum -
        cart.reduce((prev: any, cur: any) => {
          let curPrice =
            +cur.valutaRate > 0 ? +cur.price / +cur.valutaRate : +cur.price
          return (
            (prev || 0) +
            ((curPrice * (100 - cur.discount)) / 100) * cur.count * cur.shift
          )
        }, 0)
      : +cart[0].valutaRate > 0
      ? allSum -
        (((cart[0].price / +cart[0].valutaRate) * (100 - cart[0].discount)) /
          100) *
          cart[0].count *
          cart[0].shift
      : allSum -
        ((cart[0].price * (100 - cart[0].discount)) / 100) *
          cart[0].count *
          cart[0].shift
  const totalSum = allSum - discountSum
  return (
    <>
      <main className={styles.ThreeCartMain}>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3>Подтверждение заказа</h3>
            </div>
            <div className="col-12 col-xs-12">
              <p className={styles.warningMessage}>
                <img src={warningImage} alt="warningImage"></img>
                Вы сформировали этот список в корзине. После отправки заказа его
                можно будет отредактировать в личном кабинете.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <table
                className={styles.table}
                cellPadding="15px"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th align='left' scope="col">Наименование</th>
                    <th scope="col">Кол-во</th>
                    <th scope="col">Срок аренды</th>
                    <th scope="col">Кол-во смен</th>
                    <th scope="col">Скидка</th>
                    <th scope="col">Стоимость</th>
                  </tr>
                </thead>
                <tbody>{CartList}</tbody>
              </table>
              <div className={styles.ThreeCartMobileTable}>
                {CartListMobile}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-8 col-xs-12">
              <TextInput
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.feedback}
                onChange={feedback =>
                  dispatch({
                    type: actions.FEEDBACK,
                    data: feedback,
                  })
                }
                value={state.feedback || ''}
                valid={true}
                className={styles.feedback}
              />
            </div>
            <div className={styles.cart_sum + ' ' + 'col-4 col-xs-12'}>
              <div>
                <span>Сумма аренды:</span>
                <span>
                  {Math.round(allSum).toLocaleString()} {valutaIconGlobal}
                </span>
              </div>
              <div>
                <span>Скидка:</span>
                <span>
                  {Math.round(discountSum).toLocaleString()} {valutaIconGlobal}
                </span>
              </div>
              <div>
                <span>Доставка:</span>
                <span>Бесплатно</span>
              </div>
              <div>
                <b>Итого</b>
                <b>
                  {Math.round(totalSum).toLocaleString()} {valutaIconGlobal}
                </b>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ScreenThreeCart

import { PUBLIC_URL } from '../server-parameters'
import { LOCAL_STORAGE_KEYS } from '../constant'
import { State } from '../state-actions-reducers'
import { getUserAndFormId } from './useTokenCustomHook'
import { getSavedOrderId } from '../local-storage'

// const headers = new Headers({
// 	'content-type': 'application/json',
// 	accept: 'text/plain'
// })

export async function addCitizenship(
  state: State,
  phone: any,
  email: any,
  fullName: string,
  country: string
) {
  const headers = new Headers({
    'content-type': 'application/json',
    accept: 'text/plain',
  })
  if (!headers.has('authorization')) {
    const token = getSavedOrderId(window.localStorage)
    headers.set('Authorization', 'Bearer ' + token)
  }

  const fullUrl = PUBLIC_URL + '/anketa/countryRequest '

  const formData = {
    fullName: fullName,
    phone: phone,
    email: email,
    country: country,
  }
  const fetchOptions = {
    headers,
    body: JSON.stringify(formData),
    method: 'POST',
  }
  let response = await fetch(fullUrl, fetchOptions)
  if (
    response.status === 400 ||
    response.status === 401 ||
    response.status === 403
  ) {
    getUserAndFormId(() => {}, window.localStorage)
  }
  //console.log(body)
  return response
}

import React, { useRef, useState, useEffect } from 'react'

import { TextInputProps } from '../../types/common-types'
import { generateId, requestForm } from '../../utils/helpers'
import accountForm from '../../content/account-form'
import styles from '../Inputs/FileInput.module.scss'
import { actions } from '../../state-actions-reducers'
import cn from 'classnames'
import LoadingScreen from '../../Layouts/LoadingScreen'
import { getSavedFormId } from '../../local-storage'

function FileInput({
  disabled,
  lang,
  content,
  onChange,
  value,
  className,
  valid,
  required,
  focus,
  url,
  autorization,
  target,
  documentType,
  singleType,
  onBlur,
  dispatch,
  imageErrors,
  ...props
}: TextInputProps) {
  const idRef = useRef(generateId(16))
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focus])
  const [inputValue, setInputValue] = useState(
    value?.replace('C:\\fakepath\\', '') || content.placeholder[lang]
  )
  useEffect(() => {
    setInputValue(
      value?.replace('C:\\fakepath\\', '') || content.placeholder[lang]
    )
  }, [lang])
  const [errorResponse, setErrorResponse] = useState('')
  const [loading, setLoading] = useState(false)
  console.log('errorResponse', errorResponse)
  function getTranslateError(errorsStatus: number) {
    return errorsStatus === 415
    ? accountForm.uploadErrorId1[lang]
    : errorsStatus === 413
    ? accountForm.uploadErrorId0[lang]
    : accountForm.uploadErrorId2[lang]
  }
  useEffect(()=> {
    const errorStatus = imageErrors?.find((e)=> e.type === documentType)?.status
    if (errorStatus) setErrorResponse(getTranslateError(errorStatus))
  }, [imageErrors])
const token = getSavedFormId(window.localStorage)
  return (
    <div className={styles.fileInputBox}>
      <p className={styles.labelText}>
        {content.label[lang]}
        {required && <span className={styles.required}></span>}
      </p>
      <label
        htmlFor={idRef.current}
        className={cn(className, styles.label, !valid || errorResponse ? styles.invalid : '', disabled ? styles.disabled : '')}
      >
        <div className={styles.buttonBox}>
          <button className={styles.button}></button>
        </div>
        <span className={styles.valueText}>
          {loading ? <div className={styles.loader_box}>
            <LoadingScreen></LoadingScreen>
            <span>{accountForm.uploading[lang]}...</span>
          </div> : inputValue}
          {required && <span className={styles.required}></span>}
        </span>
        <input
          data-test-id={props.testName}
          id={idRef.current}
          ref={inputRef}
          disabled={disabled}
          className={`${styles.input}`}
          type="file"
          placeholder={inputValue}
          onChange={async e => {
            if (e.target.value) {
              setLoading(true)
              const value = e.target.value
              const formData = new FormData()
              formData.append('File', e.target.files ? e.target.files[0] : '')
              const result = await requestForm(
                url,
                formData,
                {
                  authorization: `Bearer ${token}`,
                },
                true,
                'Error while token generation'
              )
              if (result.errorsStatus) {
                const translateError = getTranslateError(result.errorsStatus)
                setErrorResponse(translateError)
                dispatch({
                  type: actions.IMAGE_ERROR,
                  data: !imageErrors?.find((e) => e.type === documentType) ? [...imageErrors??[], {type: documentType, status: result.errorsStatus }] : imageErrors.map((image)=> {
                    return image.type === documentType ? {...image, status: result.errorsStatus} : image
                  })
                })
              } else {
                dispatch({
                  type: actions.IMAGE_ERROR,
                  data: imageErrors?.filter((e) => e.type !== documentType)??[]
                })
                setErrorResponse('')
                console.log('target', target)
                documentType
                  ? target.find((elem: { type: string; img: string }) => {
                      return elem.type === documentType
                    })?.type && !singleType
                    ? (target.find(
                        (elem: {
                          type: string
                          img: string
                          singleType: string
                        }) => {
                          return elem.type === documentType
                        }
                      ).img = result.url)
                    : target.find(
                        (elem: {
                          type: string
                          img: string
                          singleType: string
                        }) => {
                          return elem.singleType === singleType
                        }
                      )?.singleType
                    ? (target.find(
                        (elem: {
                          type: string
                          img: string
                          singleType: string
                        }) => {
                          return elem.singleType === singleType
                        }
                      ).img = result.url)
                    : target.push({
                        type: documentType,
                        img: result.url,
                        singleType: singleType ?? '',
                      })
                  : target.push(result)
              }
              onChange(value, e)
              setInputValue(value.replace('C:\\fakepath\\', ''))
              setLoading(false)
            }
          }}
          maxLength={500}
          onBlur={onBlur}
        />
      </label>
      {!valid && content.invalidText ? (
        <span data-test-id={props.testErrorName} className={styles.invalidText}>
          {content.invalidText[lang]}
        </span>
      ) : errorResponse.length > 0 ? (
        <span data-test-id={props.testErrorName} className={styles.invalidText}>
          {errorResponse}
        </span>
      ) : null}
    </div>
  )
}

export default FileInput

import { Option } from '../types/common-types'

export const passportTypeKz: Option[] = [
  {
    value: '2',
    label: {
      eng: 'Identification',
      rus: 'Уд. личности',
      kz: 'Жеке куәлік',
    },
  },
]

export const passportTypeKzForRf: Option[] = [
  {
    value: '2',
    label: {
      eng: 'Identification',
      rus: 'Уд. личности',
      kz: 'Жеке куәлік',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
  {
    value: '1',
    label: {
      eng: 'Passport of Kazakhstan',
      rus: 'Паспорт Казахстана',
      kz: 'Қазақстан Паспорты',
    },
  },
]

export const passportTypeRfForKz: Option[] = [
  {
    value: '3',
    label: {
      eng: 'International passport',
      rus: 'Заграничный паспорт',
      kz: 'Шетелдік төлқұжат',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
  // {
  //   value: '11',
  //   label: {
  //     eng: 'Temp. residence permit',
  //     rus: 'РВП',
  //     kz: 'Уақытша тұруға рұқсат',
  //   },
  // },
  {
    value: '1',
    label: {
      eng: 'Internal passport',
      rus: 'Внутренний паспорт РФ',
      kz: 'Ішкі төлқұжат',
    },
  },
]
export const passportTypeRf: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Internal passport',
      rus: 'Внутренний паспорт РФ',
      kz: 'Ішкі төлқұжат',
    },
  },
]
export const passportTypeKgz: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Biometric passport',
      rus: 'Биометрический паспорт',
      kz: 'Биометриялық төлқұжат',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
  // {
  //   value: '11',
  //   label: {
  //     eng: 'Temp. residence permit',
  //     rus: 'РВП',
  //     kz: 'Уақытша тұруға рұқсат',
  //   },
  // },
  {
    value: '8',
    label: {
      eng: 'ID card',
      rus: 'ID карта',
      kz: 'Жеке куәлік',
    },
  },
]

export const passportTypeGbr: Option[] = [
  {
    value: '1',
    label: {
      eng: 'British Passport',
      rus: 'Паспорт гражданина Великобритании',
      kz: 'Ұлыбритания азатының төлқұжаты',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]
export const passportTypeBel: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Biometric passport',
      rus: 'Биометрический паспорт',
      kz: 'Биометриялық төлқұжат',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
  {
    value: '8',
    label: {
      eng: 'ID card',
      rus: 'ID карта',
      kz: 'Жеке куәлік',
    },
  },
]

export const passportTypeBelForRf: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Biometric passport',
      rus: 'Биометрический паспорт',
      kz: 'Биометриялық төлқұжат',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
  {
    value: '8',
    label: {
      eng: 'ID card',
      rus: 'ID карта',
      kz: 'Жеке куәлік',
    },
  },
]

export const passportTypeRom: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Simple electronic passport',
      rus: 'Простой электронный паспорт',
      kz: 'Қарапайым электрондық төлқұжат',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeGer: Option[] = [
  {
    value: '3',
    label: {
      eng: 'International passport',
      rus: 'Заграничный паспорт',
      kz: 'Халықаралық паспорт',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeUkr: Option[] = [
  {
    value: '3',
    label: {
      eng: 'International passport',
      rus: 'Заграничный паспорт',
      kz: 'Халықаралық паспорт',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeUkrForRf: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Internal passport',
      rus: 'Внутренний паспорт',
      kz: 'Ішкі төлқұжат',
    },
  },
  {
    value: '8',
    label: {
      eng: 'ID card',
      rus: 'ID карта',
      kz: 'Жеке куәлік',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
  {
    value: '3',
    label: {
      eng: 'International passport',
      rus: 'Заграничный паспорт',
      kz: 'Халықаралық паспорт',
    },
  },
]

export const passportTypeUsa: Option[] = [
  {
    value: '1',
    label: {
      eng: 'United States Passport',
      rus: 'Паспорт гражданина США',
      kz: 'АҚШ төлқұжаты ',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeAus: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Australian passport',
      rus: 'Паспорт гражданина Австралии',
      kz: 'Австралия азаматының төлқұжаты',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeTaj: Option[] = [
  {
    value: '3',
    label: {
      eng: 'International passport',
      rus: 'Заграничный паспорт',
      kz: 'Халықаралық паспорт',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeTur: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Turkish Passport',
      rus: 'Турецкий паспорт',
      kz: 'Түрік төлқұжаты',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]
export const passportTypeIrn: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Iranian passport',
      rus: 'Паспорт гражданина Ирана',
      kz: 'Иран азаматының төлқұжаты',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]
export const passportTypeNga: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Nigerian passport',
      rus: 'Паспорт гражданина Нигерии',
      kz: 'Нигерия азаматының төлқұжаты',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]
export const passportTypeChn: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Chinese passport',
      rus: 'Паспорт гражданина КНР',
      kz: 'Қытай азаматының төлқұжаты',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]
export const passportTypeUzb: Option[] = [
  {
    value: '3',
    label: {
      eng: 'International passport',
      rus: 'Заграничный паспорт',
      kz: 'Халықаралық паспорт',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeLitva: Option[] = [
  {
    value: '1',
    label: {
      eng: "Citizen's passport",
      rus: 'Паспорт гражданина',
      kz: 'Азаматтың төлқұжаты',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeSKorea: Option[] = [
  {
    value: '1',
    label: {
      eng: "South Korean passport",
      rus: 'Паспорт гражданина Южной Кореи',
      kz: 'Оңтүстік Корея төлқұжаты',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeIndia: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Indian passport',
      rus: 'Паспорт Индии',
      kz: 'Үндістанның төлқұжаты',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypePakistan: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Pakistani passport',
      rus: 'Паспорт гражданина Пакистана',
      kz: 'Пәкістан азаматының төлқұжаты',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeBrunei: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Bruneian passport',
      rus: 'Паспорт гражданина Брунея',
      kz: 'Бруней азаматының төлқұжаты',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeSwe: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Swedish passport',
      rus: 'Шведский паспорт',
      kz: 'Швед паспорты',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const DocumentTypeValuesUdvAbroad: Option[] = [
  {
    value: '3',
    label: {
      eng: 'International passport',
      rus: 'Заграничный паспорт',
      kz: 'Халықаралық паспорт',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]
export const DocumentTypeValuesTypePersonalities: Option[] = [
  {
    value: '2',
    label: {
      eng: 'Oud. personalities',
      rus: 'Уд. личности',
      kz: 'Оуд. тұлғалар',
    },
  },
]
export const DocumentTypeValuesTypePassport: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Passport',
      rus: 'Паспорт',
      kz: 'Паспорт',
    },
  },
]

export const BritishCodeType: Option = [
  {
    value: 'GBR',
    label: {
      eng: 'GBR',
      rus: 'GBR',
      kz: 'GBR',
    },
  },
  {
    value: 'GBD',
    label: {
      eng: 'GBD',
      rus: 'GBD',
      kz: 'GBD',
    },
  },
  {
    value: 'GBO',
    label: {
      eng: 'GBO',
      rus: 'GBO',
      kz: 'GBO',
    },
  },
  {
    value: 'GBS',
    label: {
      eng: 'GBS',
      rus: 'GBS',
      kz: 'GBS',
    },
  },
  {
    value: 'GBP',
    label: {
      eng: 'GBP',
      rus: 'GBP',
      kz: 'GBP',
    },
  },
  {
    value: 'GBN',
    label: {
      eng: 'GBN',
      rus: 'GBN',
      kz: 'GBN',
    },
  },
]

import React, { useEffect, useRef, useState } from "react";
import styles from './MessengersInput.module.scss'
import cn from 'classnames'
import { Country, Lang } from "../../types/common-types";
import { State, Dispatch, actions } from '../../state-actions-reducers'
import accountForm from "../../content/account-form";
import MessengersItem from "./MessengersItem";
import SelectInput from "./SelectInput";
import AddButton from "../Buttons/AddButton";
import { messengersLinksOptions } from "../../content/messengersLinks";

interface Props {
    lang: Lang;
    state: State;
    invalidFields: (string | false)[];
    dispatch: Dispatch;
    allCitizenships: Country[]
}

function MessengersInput({ lang, state, invalidFields, dispatch, allCitizenships }: Props) {
    const CommunicationTypeOptions = [
        { label: accountForm.voiceAndText, value: '0' },
        { label: accountForm.voice, value: '1' },
        { label: accountForm.text, value: '2' },
    ];
    const CommunicationTimeOptions = [
        { label: accountForm.morning, value: '1' },
        { label: accountForm.day, value: '2' },
        { label: accountForm.evening, value: '3' },
        { label: accountForm.night, value: '4' }
    ];
    const [messengersCount, setMessengersCount] = useState(1)
    const messengers = () => {
        let list = []
        for (let i = 0; i < messengersCount; i++) {
            list.push(
                <MessengersItem
                    key={`messenger-${i}`}
                    lang={lang}
                    state={state}
                    invalidFields={invalidFields}
                    dispatch={dispatch}
                    index={i}
                    disabled={(state.messengers?.[i]?.type === 'WhatsApp' && state.whatsappCheck && !!state.messengers?.[i]?.isCheck) || (state.messengers?.[i]?.type === 'Telegram' && state.telegramCheck && !!state.messengers?.[i]?.isCheck)}
                    allCitizenships={allCitizenships}
                    deleteHandler={()=> {
                        (state.messengers?.[i]?.type) ?
                            dispatch({
                                type: actions.MESSENGERS,
                                data: state.messengers.filter((e,index)=> {
                                    return i !== index
                                })
                            }) :
                            setMessengersCount(messengersCount-1)
                    }}
                />
            )
        }
        return list
    }
    const [messengersList, setMessengersList] = useState(messengers)
    useEffect(() => {
        setMessengersList(messengers)
    }, [
        messengersCount,
        invalidFields,
        state.messengers
    ])
    useEffect(() => {
        state.messengers.length > 0 && setMessengersCount(state.messengers.length)
    }, [state.messengers])
    console.log('preferredCommunicationTime', invalidFields.includes('preferredCommunicationTime'))
    return (
        <>
            <div className="row">
                <div className={cn("col-12 col-xs-12", styles.messengersTitle)}>
                    <h3>{accountForm.stepTwoMessengerTitle[lang]}</h3>
                    <span className={styles.description}>{accountForm.stepTwoMessengerDescription[lang]}</span>
                </div>
            </div>
            {messengersList}
            <div className="row">
                <div className="col-6 col-xs-12">
                    <div className={styles.addButtonBox} onClick={() => {
                        setMessengersCount(messengersCount + 1)
                    }}>
                        <AddButton />
                        <span>{accountForm.messengersButton[lang]}</span>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-6 col-xs-12">
                    <SelectInput
                        name={'preferredCommunicationType'}
                        options={CommunicationTypeOptions}
                        onChange={(e) => {
                            dispatch({
                                type: actions.COMMUNICATION_TYPE,
                                data: e
                            });
                            +e === 2 && dispatch({
                                type: actions.COMMUNICATION_TIME,
                                data: 0
                            });
                        }}
                        value={
                            CommunicationTypeOptions.find((e) => {
                                return e.value === state.preferredCommunicationType
                            })?.value ?? ''
                        }
                        lang={lang}
                        content={accountForm.preferredCommunicationType}
                        noOptionsMessage={
                            accountForm.additionalLinksDescription.placeholder
                        }
                        valid={!invalidFields.includes('preferredCommunicationType')}
                    />
                </div>
                {+(state?.preferredCommunicationType ?? 0) !== 2 && <div className="col-6 col-xs-12">
                    <SelectInput
                        name={'preferredCommunicationTime'}
                        options={CommunicationTimeOptions}
                        onChange={(e) => {
                            dispatch({
                                type: actions.COMMUNICATION_TIME,
                                data: e
                            });
                        }}
                        lang={lang}
                        value={
                            CommunicationTimeOptions.find((e) => {
                                return e.value === state.preferredCommunicationTime
                            })?.value ?? ''
                        }
                        content={accountForm.preferredCommunicationTime}
                        noOptionsMessage={
                            accountForm.additionalLinksDescription.placeholder
                        }
                        valid={!invalidFields.includes('preferredCommunicationTime')}
                    />
                </div>}
            </div>
        </>
    );
}

export default MessengersInput;

import React, { useState, useEffect, useMemo } from 'react'
import styles from './AddCitizenships.module.scss'
import cn from 'classnames'
import BlueButton from './Buttons/BlueButton'
import AccountForm from '../types/account-form.types'
import { addCitizenship } from '../hooks/useAddCitizenship'
import { State } from '../state-actions-reducers'
import Select from 'react-select'
import { allCountriesList, citizenshipsKz, citizenshipsRf } from '../content/countries'
import selectArrow from '../images/select-arrow.svg'
import PhoneInput from './Inputs/PhoneInput'
import EmailInput from './Inputs/EmailInput'
import { Dispatch, actions } from '../state-actions-reducers'
import GreenCheckMarkImage from '../images/greencheckmark.svg'
import { validateEmail, validatePhone } from '../validate/validators'

interface Props {
  state: State
  accountForm: AccountForm
  lang: string
  handlerChange: () => void
  noContacts?: boolean
  invalidFields?: string[]
  dispatch: Dispatch
}
function AddCitizenships({
  state,
  accountForm,
  lang,
  handlerChange,
  noContacts,
  invalidFields,
  dispatch,
}: Props) {
  const [country, setCountry] = useState('')
  const [optionsList, setOptionsList] = useState([{ label: '', value: '' }])
  useEffect(() => {
    let list = allCountriesList
      .map(option => {
        return { value: option.value, label: option?.label?.[lang] ?? '' }
      })
      .filter(country => {
        if (state.iframeCountry === 'kz') {
          return !citizenshipsKz.find(citizenship => {
            return citizenship.value === country.value
          })
        } else {
          return !citizenshipsRf.find(citizenship => {
            return citizenship.value === country.value
          })
        }
      })
      .sort(function(a, b) {
        if (a.label > b.label) {
          return 1
        }
        if (a.label < b.label) {
          return -1
        }
        return 0
      })
    setOptionsList(list)
  }, [lang, allCountriesList])
  const [successMessage, setSuccessMessage] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  function validateForm() {
    const errorsList = [
      !state.emails.some(
        email => validateEmail(email, true) && state.isEmailOk
      ) && 'emails',
      !state.mobilePhoneNumbers.some(
        number => validatePhone(number) && state.isPhoneOk
      ) && 'mobilePhoneNumbers',
      country?.length === 0 && 'country'
    ].filter(Boolean) as string[]
    setErrors(errorsList)
    if (errorsList.length === 0) {
      addCitizenship(
        state,
        state.mobilePhoneNumbers[0],
        state.emails[0],
        `${state.lastName} ${state.firstName} ${state.patronymic}`,
        country
      )
        .then(e => {
          setSuccessMessage(true)
        })
        .catch(err => {
          console.log('err', err)
        })
    }
  }
  return (!successMessage ?
    <div className={styles.addCitizenshipsContainer}>
      <p>{accountForm.noCitizenshipsDesc[lang]}</p>
      <form action="">
        {noContacts ? (
          <div className={styles.contactsBlock}>
            {/* MOBILE PHONE NUMBER */}
            <PhoneInput
              label={accountForm.mobilePhoneNumber.label[lang]}
              numbers={
                state.mobilePhoneNumbers.length === 0
                  ? ['']
                  : state.mobilePhoneNumbers
              }
              lang={lang}
              setNumbers={data => {
                dispatch({
                  type: actions.MOBILE_PHONE_NUMBERS,
                  data,
                })
                if (!state.Confirm_phone_ok)
                  //&& !accountForm.Confirm_phone)
                  dispatch({
                    type: actions.Confirm_phone,
                    data: data[0] || '',
                  })
              }}
              invalidText={accountForm?.mobilePhoneNumber?.invalidText?.[lang]}
              required
              firstlocked={state.Confirm_phone_ok}
              valid={state.mobilePhoneNumbers.some(
                number => validatePhone(number) && state.isPhoneOk
              ) || !errors.includes('mobilePhoneNumbers')}
              phoneCheck={
                state?.mobilePhoneNumbers[0]?.length > 0
                  ? !state.isPhoneOk
                  : false
              }
            />
            {/* EMAIL */}
            <EmailInput
              emails={state.emails.length === 0 ? [''] : state.emails}
              setEmails={data => {
                dispatch({
                  type: actions.EMAILS,
                  data,
                })
                if (!state.Confirm_mail_ok)
                  //&& !accountForm.Confirm_mail)
                  dispatch({
                    type: actions.Confirm_mail,
                    data: data[0] || '',
                  })
              }}
              placeholder={accountForm.email.placeholder[lang]}
              invalidText={accountForm.email.invalidText[lang]}
              invalidTextCorrect={accountForm.email.invalidTextCorrect[lang]}
              accountForm={accountForm}
              lang={lang}
              required
              firstlocked={state.Confirm_mail_ok}
              valid={state.emails.some(
                email => validateEmail(email, true) && state.isEmailOk
              ) || !errors.includes('emails')}
              mailCheck={state.emails[0]?.length ? !state.isEmailOk : false}
            />
          </div>
        ) : null}
        <Select
          className={cn(styles.addCitizenshipSelect, (errors.includes('country')) && styles.error)}
          type="text"
          placeholder={accountForm.noCitizenshipsPlaceholder[lang]}
          options={optionsList}
          onChange={e => {
            setCountry(e?.label ?? '')
            setErrors(errors.filter((e)=> e !== 'country'))
          }}
          classNamePrefix={styles.inputItem}
          styles={{
            valueContainer: styles => ({
              ...styles,
              boxSizing: 'border-box',
              fontStyle: 'normal',
              fontSize: '15px',
              height: '52px',
              padding: '17px 20px',
              borderRadius: '7px',
              fontWeight: 'normal',
            }),
            control: (provided, { isFocused }) => ({
              ...provided,
              border: '1px solid #E4E5E7',
              borderRadius: '7px',
              outline: '0',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
              },
              background: isFocused ? '#F8F8F8' : '#fffff',
            }),
            option: (styles, { isSelected }) => ({
              ...styles,
              border: 'none',
              color: isSelected ? '#3E85FD' : '#222222',
              background: isSelected ? '#f6fbfe' : '#fffff',
              '&:hover': {
                background: '#f6fbfe',
              },
              fontSize: '14px',
              fontWeight: '400',
            }),
            placeholder: (provided, state) => ({
              ...provided,
              color: '#b8b8b8',
            }),
            indicatorsContainer: styles => ({
              background: `url(${selectArrow})`,
              backgroundRepeat: 'no-repeat',
              backgroundPositionY: 'center',
              width: '13.34px',
              height: '7.66px',
              margin: '0 19.33px 0 0',
            }),
          }}
        />
        <BlueButton
          value={accountForm.noCitizenshipsButton[lang]}
          onClick={async () => {
            validateForm();
          }}
        />
      </form>
      {errors.includes('country') ? (
        <span className={styles.invalidText}>
          {accountForm.citizenshipsInvalid[lang]}
        </span>
      ) : null}
    </div> : <div className={styles.successCitizenshipsContainer}>
      <img src={GreenCheckMarkImage} alt="GreenCheckMarkImage" />
      <div>
        <b>Заявка на добавление страны успешно отправлена!</b>
        <p>Наши менеджеры скоро свяжутся с Вами.</p>
      </div>
    </div>
  )
}

export default AddCitizenships

import { useEffect } from 'react'
import { request } from '../utils/helpers'

import { LOCAL_STORAGE_PRODUCTS_KEYS } from '../constant'
import { API_URL, BACKEND_URL, PUBLIC_URL } from '../server-parameters'
import { ObjectValueNode } from 'graphql'
import { getSavedOrderId } from '../local-storage'
import { State } from '../state-actions-reducers'

// const headers = new Headers({
// 	'content-type': 'application/json',
// 	accept: 'text/plain'
// })

interface UseProductsCallback {
  ({
    categories,
    catalogItems,
  }: {
    categories: Array<any>
    catalogItems: Array<any>
  }): void
}

export async function getCity() {
  const headers = new Headers({
    'content-type': 'application/json',
    accept: 'text/plain',
  })
  if (!headers.has('authorization')) {
    const token = window.localStorage.FORM_ID
    headers.set('Authorization', 'Bearer ' + token)
  }

  const fullUrl = PUBLIC_URL + '/PublicContent/instanceSettings'
  const fetchOptions = {
    headers,
    method: 'Get',
  }
  let response = await fetch(fullUrl, fetchOptions)

  return response.json()
}

export async function getCitizenships() {
  const headers = new Headers({
    'content-type': 'application/json',
    accept: 'text/plain',
  })
  if (!headers.has('authorization')) {
    const token = window.localStorage.FORM_ID
    headers.set('Authorization', 'Bearer ' + token)
  }

  const fullUrl = PUBLIC_URL + '/PublicContent/citizenships'
  const fetchOptions = {
    headers,
    method: 'Get',
  }
  let response = await fetch(fullUrl, fetchOptions)

  return response.json()
}
